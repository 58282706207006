/*! -- Stack Blog -- */
article{
	.page-spacer {
		margin: 1.5em 0em 2em -5%;
		width: 110%;
	}
	.header-image {
		img {
			width: 100%;
			height: inherit;
		}
	}
	.article__title{
		>a{
			display: block;
			&:hover{
				text-decoration: none;
				opacity: .75;
			}
		}
		span.category {
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 15px;
			letter-spacing: 3px;
			display: block;
		}
		span.posted {
			font-size: 16px;
			font-weight: 400;
			margin-top: -10px;
			font-family: 'Amiri', serif;
			color: @color-primary;
			font-style: italic;
		}
		h2{
			margin-bottom: 0.3939393939394em;
		}
		h1{
			margin-bottom: 0;
		    font-size: 2.3em;
		    color: @color-primary-1;
		    font-weight: 700;
		    // margin: 0 0 20px;
		    padding: 0;
		    line-height: 1.3em;
		    width: 60%;
		    @media all and (max-width: 767px){ 
				width: 100%;
			}
		}
		margin-bottom: @base-line-height;
	}
	.article__body{
		position: relative;
		.wp-block-gallery {
			margin: 35px 0 20px;
		}
		h2 {
			padding-top: .5em;
			padding-bottom: 0px;
			margin-bottom: .75em;
		}
		h3 {
			font-size: 30px;
			padding-top: .5em;
			padding-bottom: 0px;
			margin-bottom: .75em;
		}
		h4 {
			font-weight: 600;
			font-size: 24px;
			padding-top: 10px;
		}
		h5 {
			font-weight: 600;
			font-size: 18px;
		}
		img,iframe,.video-cover{
			border-radius: 6px;
			&:not(:last-child){
				margin-bottom: @base-line-height;
			}
		}
		img.alignleft {
			float: left;
			margin-right: 25px;
			margin-bottom: 15px;
		}
		img.alignright {
			float: right;
			margin-left: 25px;
			margin-bottom: 15px;
		}
		a {
			color: @color-primary-1;
		}
		> a{
			&:first-child{
				> img{
					margin-bottom: @base-line-height;
				}
			}
		}
		// p {
		// 	font-size: 16px !important;
		// 	line-height: 24px !important;
		// }
		p,ul{
			font-size: 16px !important;
			line-height: 24px !important;
			color:#444 !important;
			li {
				list-style: outside;
			}
			.lead;
			+h5{
				margin-top: @base-line-height*1.5;
			}
		}
		ul {
			margin-left: 20px;
		}
		blockquote{
			margin: 0px !important;
			text-align: center;
			&:not(:last-child){
				margin-top: 2.08em;
				margin-bottom: 2.08em;
			}
		}
	}
	.article__share{
		margin-top: @base-line-height * 3;
	}
	&.imagebg{
		padding: @base-line-height;
		overflow: hidden;
		&:before{
			.transition(0.3s,ease);
		}
		&:hover{
			&:before{
				opacity: .6;
			}
		}
		.article__title{
			margin: 0;
			position: absolute;
			bottom: 30px;
			z-index: 2;
			span{
				font-size: @fine-print-size;
			}
			max-width: 90%;
		}
		.label{
			z-index: 2;
			top: @base-line-height;
			right: @base-line-height;
		}
	}
	&.feature-1{
		h5{
			min-height: @base-line-height*2;
			font-weight: 400;
			font-size: 24px;
			line-height: 28px;
			color: @color-primary-1;
		}
		span {
			font-size: .9em;
		}
		a{
			img{
				.transition(0.3s,ease);
			}
			&:hover{
				img{
					opacity: .85;
				}
			}
		}
	}
	.post_slider_container {
		position: relative;
	}
	.post_slider {
		overflow: hidden;
		.slick-list {
			border: 1px solid #ccc;
			padding: 20px 15px 25px;
		}
	}
	.post_slide_nav {
		position: absolute;
		top: 15px;
		right: 15px;
		background: white;
		.prev {
			margin-right: 5px;
		}
		.next, .prev {
			position: relative !important;
			display: inline-block;
			i {
				font-size: 1.5rem;
			}
		}
	}
	.post_slide {
		margin-bottom: 2em;
		padding: 0px 1em !important;
		height: inherit !important;
		hgroup {
			h3 {
				font-size: 2.3em;
			    color: @color-primary;
			    font-weight: 100;
			    margin: 0;
			    padding: 0;
			    font-style: italic;
			    font-family: Amiri,serif;
			    letter-spacing: .5px;
			}
			h4 {
				font-size: 1em;
			    color: #d0021b;
			    font-weight: 600;
			    text-transform: uppercase;
			    letter-spacing: 3px;
			    line-height: 24px;
			    margin:0 0 15px;
			    padding: 0;
			}
		}
		.image {
			margin-bottom: 2em;
		}
		
	}
}

figure[class*='col-']{
	padding: 0 !important;
}
figure.pull-right{
	margin-left: @base-line-height*1.5;
}
figure.pull-left{
	margin-right: @base-line-height*1.5;
}
figure{
	img{
		&:not(:last-child){
			margin-bottom: @base-line-height/4 !important;
		}
	}
	figcaption{
		font-size: @h6-size;
		font-style: italic;
	}
}

section.unpad{
	> article{
		.imagebg + div{
			padding: @base-line-height *3 0;
		}
	}
}

.article__author{
	img{
		max-height: @base-line-height*2;
		margin-bottom: @base-line-height/4;
	}
	padding-bottom: @base-line-height;
}

.slides--gapless{
	article{
		&.imagebg{
			height: @base-line-height*15;
		}
	}
}

@masonry-item-height: 338px;

.masonry-blog-magazine{
	article, .masonry__promo{
		height: @masonry-item-height;
	}
	.height--tall{
		height: calc((@masonry-item-height*2) + 30px);
	}
}

@media all and (min-width: 991px){
	.masonry__promo{
		&.boxed--lg{
			padding: @base-line-height*4;
		}
	}
}

@media all and (max-width: 990px){
	.masonry-blog-magazine{
		.height--tall{
			height: @masonry-item-height;
		}
	}
	.masonry__promo{
		.pos-vertical-center{
			top: 0;
			transform: none;
		}
	}
}

@media all and (max-width: 1200px){
	.masonry__promo{
		&.boxed--lg{
			padding: @base-line-height*2;
		}
	}
}

.pagination{
	text-align: center;
	&:after{
		content: "";
		display: table;
		clear: both;
	}
	margin-top: @base-line-height;
	a{
		font-weight: normal;
	}
	div[class*='col-']{
		padding: 0;
	}
	ol{
		margin-bottom: 0;
		display: inline-block;
		li{
			display: inline-block;
			margin: 0;
			font-weight: 700;
			line-height: 23px;
			&:first-child {
				border: none;
				width: inherit;
			}
			&:last-child {
				border: none;
				width: inherit;
			}
			> a{
				font-weight: 700;
			}
			&:not(:last-child){
				margin-right: @base-line-height/4;
			}
			width: @base-line-height;
			height: @base-line-height;
			border-radius: 50%;
			border: 1px solid #ececec;
			&.pagination__current{
				cursor: default;
				background: #ececec;
				color: #aaa;
			}
		}
	}
}

.pagination__prev, .pagination__next{
	display: inline-block;
}
.pagination__next{
	float: right;
}
.pagination__prev{
	float: left;
}

.blog-article-wide{
	&:not(:last-child){
		border-bottom: 1px solid #ececec;
		margin-bottom: @base-line-height*2;
	}
}

.masonry-blog-list{
	hr+.masonry__container{
		margin-top: 0;
	}
	article{
		border-bottom: 1px solid #ececec;
		padding-top: @base-line-height*1.5;
		margin-bottom: @base-line-height*3;
		.article__body{
			padding-bottom: @base-line-height*3;
		}
	}
}

.masonry-blog-magazine{
	.masonry__item{
		a{
			font-weight: normal;
			&:hover{
				text-decoration: none;
			}
		}
	}
}

.masonry-filter-container{
	> span{
		position: relative;
		+.masonry-filter-holder{
			margin-left: @base-line-height/2;
		}
	}
	margin-bottom: @base-line-height;
	padding: 0px @base-line-height/2;
	.input-select {
		margin-bottom: 15px;
	}
}

.comments__list{
	li{
		padding: @base-line-height;
		border: 1px solid #ececec;
		border-bottom: none;
		&:last-child{
			border-bottom: 1px solid #ececec;
		}
		&:nth-child(odd){
			background: darken(@color-bg-site, 1%);
		}
		&:nth-child(even){
			background: darken(@color-bg-site, 2.5%);
		}
		&:first-child{
			border-radius: 6px 6px 0 0;
		}
		&:last-child{
			border-radius: 0 0 6px 6px;
		}
	}
}

.bg--secondary{
	.comments__list{
		li{
			&:nth-child(even){
				background: #fff;
			}
		}
	}
}

@media all and (min-width: 768px){
	.comments__list{
		li{
			padding: @base-line-height*1.5;
		}	
	}
}

.comment{
	overflow: hidden;
	&:not(:last-child){
		border-bottom: 1px solid #ececec;
		padding-bottom: @base-line-height;
	}
	.comment__avatar{
		width: 15%;
		float: left;
		img{
			max-height: @base-line-height*2;
		}
	}
	.comment__meta{
		font-size: @fine-print-size;
		a{
			display: inline-block;
			&:not(:first-child){
				margin-left: 1.08333333333334em;
			}
		}
		+p{
			margin-top: @base-line-height/2;
		}
	}
	.comment__body{
		width: 85%;
		float: right;
		h5{
			&.type--fine-print{
				margin: 0;
			}
		}
	}
	+.comment{
		margin-top: @base-line-height;
		padding-left: 15%;
	}
}

@media all and (min-width: 768px){
	.comments{
		+.comments-form{
			margin-top: @base-line-height*2;
		}
	}
}

@media all and (min-width: 768px){
	h3{
		+ .related-blog-list{
			margin-top: @base-line-height*2;
		}
	}
}

.related-blog-list{
	li{
		&:not(:last-child){
			margin-bottom: @base-line-height;
		}
	}
	article{
		img{
			border-radius: 6px;
		}
		.article__title{
			margin-top: @base-line-height/2;
			margin-bottom: 0;
			.h4{
				margin-bottom: 0.68421052631579em;
			}
		}
	}
}

@media all and (min-width: 991px){
	.sidebar{
		margin-top: @base-line-height*4;
		position: relative;
		bottom: 18px;
	}
}

.sidebar__widget{
	> h5{
		&:first-child{
			margin-bottom: @base-line-height/2;
		}
	}
	&:not(:last-child){
		margin-bottom: @base-line-height*2;
	}
}

.tag-cloud{
	li{
		display: inline-block;
		&:not(:last-child){
			margin-right: @base-line-height/4;
			&:after{
				content: ',';
			}
		}
	}
}

.conversation__head{
	border-radius: 6px 6px 0 0;
	&.boxed:not(:last-child){
		margin: 0;
	}
	h4{
		margin: 0;
	}
	.conversation__avatar{
		img{
			max-height: @base-line-height;
			display: inline-block;
			margin-right: @base-line-height/4;
			margin-bottom: 0;
		}
		margin-top: @base-line-height/2;
	}
}

@media all and (min-width: 768px){
	.conversation__avatar{
		position: absolute;
		top: @base-line-height;
		right: @base-line-height*1.5;
	}
}

.conversation__reply{
	border-top: none !important;
	border-bottom: none;
	border-radius: 0;
	&.boxed:not(:last-child){
		margin-bottom: 0;
	}
	+ .conversation__comments{
		.comments__list{
			li:first-child{
				border-radius: 0;
				border-top: none;
			}
		}
	}
}