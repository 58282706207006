/*! -- Stack Buttons -- */

.white {
	border-radius: 100px !important;
	color: white;
	padding-top: @base-line-height/2 !important;
	padding-bottom: @base-line-height/2 !important;
	padding-left: @base-line-height*2 !important;
	padding-right: @base-line-height*2 !important;
	text-transform: uppercase;
	font-size: 12px;
	cursor: pointer !important;
	letter-spacing: 1px;
	box-shadow: 0 12px 22px 0 rgba(0,0,0,0.24);
	font-weight: 600;
	position: relative;
	z-index: 20;
}

@media all and (max-width: 767px){ 
	.white {
		padding-left: @base-line-height*1.5 !important;
		padding-right: @base-line-height*1.5 !important;
	}
}

.btn-red{
	background-color: @color-primary-1;
	border: none;
	color: white;
	border-radius: 5px;
	padding-top: @base-line-height/3 !important;
	padding-bottom: @base-line-height/3 !important;
	padding-left: @base-line-height !important;
	padding-right: @base-line-height !important;
	letter-spacing: 1px;
	width: 100%;
	text-align: left;
	i {
		color: white;
		float: right;
		padding: 5px 0;
	}
}

.btn-red-small {
	background-color: @color-primary-1;
	border: none;
	color: white;
	display: inline-block;
	padding-top: @base-line-height/4 !important;
	padding-bottom: @base-line-height/4 !important;
	padding-left: @base-line-height !important;
	padding-right: @base-line-height !important;
	letter-spacing: 1px;
	border-radius: 5px;
}

.btn-outline {
	box-shadow: 0 0 3px #a4d3f6;
	display: block;
	border-radius: 5px;
	padding: 3px 3px 3px 7px;
	width: 100%;
	font-weight: 600;
	font-size: 1em;
	text-align: left;
	transition: all 0.3s ease;
	color: @color-primary;
	position: relative;

	i {
		font-size: .8em;
		color: @color-primary-1;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		transition: all 0.3s ease
	}

	&:hover {
		box-shadow: 0 0 10px #a4d3f6;
		margin-top: -5px;
		text-decoration: none;

		i {
			right: 7px;
		}
	}
}

.btn-big {
	font-size: 16px;
}

.btn{
	position: relative;
	&:not([class*='primary']){
		border-color: #d3d3d3;
		&:hover{
			border-color: @color-dark;
		}
	}
	&.type--uppercase{
		letter-spacing: .5px;
		.btn__text{
			letter-spacing: .5px;
			margin-right: -.5px;
		}
	}
	.label{
		top: -@base-line-height/2;
		right: -@base-line-height*2;
	}
	&.btn--lg{
		.btn__text{
			font-weight: 600;
		}
		&.type--uppercase{
			.btn__text{
				letter-spacing: 1px;
			}
		}
	}
	+p.type--fine-print,+span.type--fine-print{
		margin-top: 1.08333333333334em;
	}
	&.block{
		margin-left: 0;
		+.btn.block{
			margin-top: @base-line-height/2;
		}
	}
	&:hover{
		.translate3d(0,-2px,0);
	}
	&.btn--sm{
		+.btn--sm{
			margin-left: @base-line-height/2;
		}
	}
}

p{
	> .btn{
		text-decoration: none;
	}
}

// @media all and (max-width: 767px){
// 	.btn{
// 		&:not(:last-child){
// 			margin-bottom: @base-line-height;
// 		}
// 	}
// }

.btn--icon{
	padding-left: @base-line-height*3;
	
	i{
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(0,0,0,.1);
		padding: 0 13px;
		border-radius: @button-radius 0 0 @button-radius;
		font-size: 16px;
		line-height: 55px;
		&.socicon{
			line-height: 42px;
		}
	}
}

.btn--cart{
	width: @base-line-height*2;
	height: @base-line-height*2;
	border: 2px solid @color-dark;
	display: block;
	text-align: center;
	border-radius: 6px;
	opacity: .4;
	padding: 0;
	&:hover{
		opacity: 1;
	}
	.btn__text{
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1.142857142857143em;
	}
}

.imagebg:not(.image--light){
	.btn--icon{
		&:not([class*='bg']):not([class*='primary']){
			i{
				color: #fff;
				background: rgba(255,255,255,.2);
			}
		}
	}
}

@media all and (min-width: 768px){
	.btn-group{
		.btn{
			margin-bottom: 0 !important;
			margin-left: 0;
			+.btn{
				margin-left: 0;
			}
			&:not(:first-child):not(:last-child){
				border-radius: 0;
			}
			&:first-child{
				border-radius: 6px 0 0 6px;
			}
			&:last-child{
				border-radius: 0 6px 6px 0;
			}
			&:first-child:nth-last-child(2),&:last-child:nth-child(2){
				width: 50%;
			}
		}
	}
}

.btn-group{
	border: none;
	padding: 0;
}

@media all and (max-width: 767px){
	.btn-group{
		.btn{
			display: block;
		}
	}
}


.bg--primary{
	div:not([class*='feature']){
		.btn--primary{
			background: #fff;
			border-color: #fff;
			.btn__text{
				color: @color-primary;
			}
		}
	}
	> .btn--primary{
		background: #fff;
		border-color: #fff;
		.btn__text{
			color: @color-primary;
		}
	}
}

.bg--dark{
	.btn{
		&:not(.btn--primary){
			border-color: lighten(@color-dark, 10%);
			&:hover{
				border-color: lighten(@color-dark, 25%);
			}
		}
		.btn__text{
			color: #fff;
		}
	}
}

.imagebg:not(.image--light){
	.btn{
		&:not([class*='primary']){
			border-color: rgba(255,255,255,0.3);
			&:hover{
				border-color: #fff;
			}
		}
	}
}

.imagebg.image--light{
	.btn{
		&:not([class*='primary']){
			border-color: @color-dark;
			background: rgba(255,255,255,.3);
			&:hover{
				background: rgba(255,255,255,.5);
			}
		}
	}
}

h1,.h1{
	+.btn{
		margin-top: @base-line-height;
	}
}

h2,.h2{
	+.btn{
		margin-top: @base-line-height/2;
	}
}

.sticky-tab {
	position: fixed;
	top: 300px;
	left: -42px;
	transform: rotate(90deg);
	background-color: @color-primary-1;
	padding: .5em 1em;
	z-index: 10;
	color: white;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 0px 30px 0 rgba(0,0,0,0.34);
	&:hover {
		text-decoration: none;
		background-color: #e9021e;
		color: #04052d;
	}
}