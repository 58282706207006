// MOBILE MENU //

#mobile-menu {
	display: none;
	background-color: #fff !important;
	border: none !important;
	box-shadow: 6px 0px 20px rgba(0,0,0,.2);
	z-index: 0;
	transition: all 0.5s ease;

	&.mm-menu_opened {
		display: block;
	}

	img#mobile-logo {
		height: 80px;
	    position: relative;
	    top: 0px;
	    bottom: 0px;
	    margin: -40px 0 10px;
	}

	.mm-navbar, .mm-navbars_top {
		border: none !important;
		background-color: white !important;
	}

	.mm-navbars_has-tabs {
		margin: 20px 100px;

		a {
			background-color: white;
			border: 1px solid #ccc !important;
			border-radius: 0px 100px 100px 0 ;
			font-weight: 500 !important;
			text-transform: uppercase;
			font-size: .8em !important;

			&.mm-navbar__tab_selected {
				color: @color-primary !important;
				margin-bottom: 0px !important;
			}
		}

		#resources {
			background-color: @color-primary-1;
			color: white !important;
		}

		a:first-child {
			border-radius: 100px 0 0px 100px;
			margin-right: -1px;
		}

	}

	.mm-navbar__title {
		display: none;
	}

	.mm-btn_prev:before {
		border: none !important;
		content: '\f060';
		font-family: 'Font Awesome 5 Pro';
		font-weight: 400 !important;
		transform: rotate(0deg) !important;
		color: @color-primary;
	}

	#mm-1, #mm-2, #mm-3 {
		border-top: 1px solid #ddd;
		background-color: #f8f8f8 !important;
		box-shadow: 0px 0 10px rgba(0,0,0,.1);
		padding-left: 40px;
		padding-right: 40px;
		margin-top: 20px;
		.mm-navbar {
			background-color: #f8f8f8 !important;
		}
		.mm-listview {
			.mm-listitem {
				font-size: 1.5em !important;
			}
		}
	}

	.mm-panel, .mm-panels {
		background: #fff !important;
		padding-left: 50px;
		padding-right: 50px;

		.mm-listitem {
			border: none !important;
			font-size: 1.8em !important;
			font-weight: 500 !important;
			transition: all .5s ease;

			&:after {
				border: none !important;
			}

			&:before {
				transition: all .5s ease;
			}

			&:hover {
				transition: all .5s ease;

				&:before {
					content: '';
					background: lighten(@color-primary, 75%) !important;
					position: absolute;
					left: 0px;
					right: 0px;
					bottom: 10px;
					height: 20px;
					z-index: 4;
					width: 100%;
					border: none !important;
				}

			}

			a {
				border: none !important;
				color: @color-primary !important;
				position: relative;
				z-index: 5;
				transition: all .5s ease;
				border-bottom: 1px solid #eee !important;

				span {
					font-size: .5em !important;
					color: @color-primary !important;
					position: absolute !important;
					left: 96px;
    				top: 5px;
    				transition: all .5s ease;

				}

				&:after {
					border-color: @color-primary;
				}

				&:hover {
					transition: all .5s ease;
					color: @color-primary-1 !important;
					padding-left: 25px;
					background-color: transparent !important;

					&:after {
						border-color: @color-primary-1;
					}
					span {
						color: @color-primary-1 !important;
						left: 101px;

					}
				}
			}
		}

		#resources {
			a {
				color: @color-primary-1 !important;
				span {
					left: 119px !important;
					color: @color-primary-1 !important;
				}

				&:hover {
					span {
						left: 124px !important;
					}
				}
			}
		}

		#panel-menu {

		}

		.sub-menu {
			li {
				a {
					font-size: .6em;
					line-height: .6em !important;
					font-weight: 400 !important;
					border-bottom: none !important;
				}

				&:hover {
					transition: all .5s ease;

					&:before {
						content: '';
						background: lighten(@color-primary, 75%) !important;
						position: absolute;
						left: 0px;
						right: 0px;
						bottom: 6px !important;
						height: 10px !important;
						z-index: 4;
						width: 100%;
						border: none !important;
					}

				}
			}
		}

		// a {
		// 	color: white !important;
		// }

		.mm-navbar {
			display: none;
		}

		#mm-1, #mm-2, #mm-3 {
			.mm-navbar {
				display: block;
			}
		}

		// span, .mm-btn_next:after, .mm-btn_prev:before {
		// 	color: white !important;
		// 	border-color: white !important;
		// }

		// #panel-resources {
		// 	background-color: @color-primary-1 !important;
		// 	.mm-navbar {
		// 		background: @color-primary-1 !important;
		// 		display: block;
		// 	}
		// }

		// .sub-menu {
		// 	text-align: center !important;
		// 	li.inline {
		// 		display: inline-block;
		// 		width: 32.33%;
		// 		text-align: center;
		// 	}
		// 	.mm-listitem_divider {
		// 		padding-top: 0px !important;
		// 		padding-bottom: 0px !important;
		// 		background: rgba(255,255,255,.4) !important;
		// 		color: white !important;
		// 		align-items: center !important;
  //   			justify-content: center !important;
		// 	}
		// }
	}

	// .mm-navbars_top {
	// 	border-bottom: none !important;
	// 	background: #0b0349 !important;
	// 	color: white !important;
	// }

	// #resources {
	// 	background: @color-primary-1 !important;
	// 	color: white !important;
	// }

}

.nav--fixed{
	.box-shadow;
}

.menu-horizontal > li, .menu-vertical > li{
	font-family: @nav-item-font, 'Roboto', 'Helvetica', Sans-Serif;
}

img.logo-white {
	display: none;
}

img.logo-regular {
	display: inline-block;
	margin-bottom: 0px;
}

.transparent {
	img.logo-white {
		display: inline-block;
	}

	img.logo-regular {
		display: none;
	}
}

#menu, #top-menu {
	&.transparent {
		position: absolute;
		left: 0px;
		right: 0px;
		width: 100%;
		background: transparent;

		.dropdown > span {
			color: white;
			opacity: 1;
			&:hover {
				color: @color-primary-1 !important;
			}
		}

		.dropdown--active > span,  {
			color: @color-primary-1 !important;
		}
	}
}

#top-menu {
	&.transparent {
		a {
			color: white;
			opacity: 1;
		&:hover {
			color: @color-primary;
		}
		}
	}
}

#menu {
	&.transparent {
		top: 40px;
		li > a {
			color: white;
			opacity: 1;
		}
	}
}

@media all and (min-width: 768px){
	.nav-bar{
		.menu-module{
			position: relative;
			top: 6px;
		}
	}
	.logo{
		position: relative;
		top: 6px;
	}
}

.nav-container {
	position: relative;
	z-index: 11;
}

h3.nav-title {
	font-size: 24px;
	line-height: 24px;
	font-weight: 500;
	color: @color-primary;
	letter-spacing: .5px;
}

.resources {
	text-align: center;
	border: 1px solid @color-primary;
	width: 100%;
	span {
		font-weight: 500;
		color: @color-primary;
	}
	span, a{
		display: inline-block;
		padding-right: 10px;
		padding-left: 10px;
		text-transform: uppercase;
		font-size: 12px;

		&:hover {
			text-decoration: underline !important;
		}
	}
	a {
		font-weight: 500;
		color: @color-primary-1;
	}
}

.links {
	a {
		color: @color-primary-1;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin: 0px;
		padding: 0px;
		display: block;
		font-weight: 500;
		font-size: 1em;
		&:hover {
			text-decoration: underline !important;
		}
	}
	p {
		font-style: italic;
		font-size: 12px;
		color: @color-primary;
		padding: 0px;
		margin: 0px;
		line-height: 18px;
	}
}

.call-out {
	padding: 0px;
	background-color: @color-primary;
	display: flex;
	height: 201px;
	> div {
		width: 100%;
	}
	h3 {
		color: white;
		margin: 0px;
		padding: 0 0 15px;
		font-size: 16px;
		line-height: 18px;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	img {
		margin-bottom: 8px;
		transition: all 1s ease;
	}
	&:hover {
		img {
			transform: scale(1.1);
		}
	}
	
	p {
		color: white;
		margin: 0px;
		padding: 0px;
		font-size: 12px;
		line-height: 18px;
	}
	a.btn {
		font-weight: 600;
		margin: @base-line-height;

	}

}

/*! Nav Items */
a.boat-option {
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: white;
	text-align: center;
	z-index: 1;
	position: relative;
	overflow: hidden;
	border-left: 10px solid #fff;
	border-top: 10px solid #fff;

	img {
		transition: all 1s ease;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}

	img {
		margin: 0px;
		padding: 0px;
		width: 100%;
		height: 201px;
	}

	&.last-option {
		border-right: 10px solid #fff;
	}

	&.bottom-option {
		border-bottom: 10px solid #fff;
	}

	p.title {
		position: absolute;
		transform: translateX(-50%);
		width: 100%;
		bottom: 0px;
		left: 50%;
		z-index: 2;
		font-weight: 500;
		font-size: 12px;
	}
}

.backdrop {
	position: absolute;
	left: 0px;
	top: 0px;
	opacity: 0;
	background: rgba(239,247,250,.8);
	transition: all 0.3s ease;
	width: 100%;
	height: 100%;
	z-index: 1;
	
	&.opacity{
		opacity: .8;
		z-index: 10;
	}
}


// .nav--fixed{
// 	animation: fadeInDown .3s ease forwards;
// 	-webkit-animation: fadeInDown .3s ease forwards;
// }

#top-menu, #menu {
	border-bottom: 1px solid @border-color;
	background: white;
}

.mini-menu {
	> li {
		display: inline;
		margin-right: 1.125em;
		&.social-icon {
			margin-right: 0px;
		}
		> a {
			display: inline-block;
			font-weight: 600;
			letter-spacing: 3px;
			font-size: 12px;
			color: @color-primary-1;
			text-transform: uppercase; 
			&:hover {
				text-decoration: none !important;
			}
		}
	}
}


.menu-horizontal{
	> li{
		> a, > span{
			font-size: 16px;
			color: @color-primary;
			letter-spacing: 4px;
			text-transform: uppercase;
			font-weight: 400;
			&:not(:hover){
				opacity: .75;
			}
		}
	}
}

.nav-bar-1{
	padding: @base-line-height/1.5 0;
	.menu-horizontal{
		> li{
			>a,>span{
				text-transform: uppercase;
				font-size: @h6-size;
				font-weight: 600;
				letter-spacing: .5px;
			}
		}
	}
}

// Mobile
@media all and (max-width: 990px){
	.nav-bar-1{
		.logo-module{
			position: relative;
			left: @base-line-height/2;
			top: 7px;
		}
		.nav-mobile-toggle{
			top: 7px;
		}
	}
}

.nav-bar-2{
	.menu-horizontal{
		> li{
			> a, >span{
				font-weight: 400;
			}
		}
	}
}

// Desktop

@media all and (min-width: 768px){
	.nav-bar-2{
		.menu-module{
			position: relative;
			top: 9px;
		}
	}
}

// Tablet

@media all and (max-width: 990px){
	.nav-bar-2{
		.logo-module{
			position: relative;
			bottom: 9px;
		}
		.nav-mobile-toggle{
			top: 24px;
		}
	}
}

// Mobile

@media all and (max-width: 767px){
	.nav-bar-2{
		.logo-module{
			position: relative;
			bottom: 2px;
		}
	}	
}

@media all and (min-width: 768px){
	.nav-bar-fullscreen{
		.logo-module{
			position: relative;
			bottom: 10px;
		}	
	}
}

// Desktop
@media all and (min-width: 990px){
	.nav-bar-fullscreen{
		.logo-module{
			text-align: center;
		}
	}
}

.menu-toggle{
	user-select: none;
	> span{
		display: inline-block;
		transform: scaleX(2.5) scaleY(1.2);
		.transition(0.2s,ease-out);
		&:hover{
			transform: scaleX(2.5) scaleY(1.8);
		}
	}
}

.menu-fullscreen{
	.logo{
		max-height: @base-line-height*1.25;
		position: relative;
		margin-bottom: @base-line-height*2.5;
	}
	.modal-content{
		.modal-close-cross{
			top: 0;
			right: @base-line-height;
			&:before{
				color: #fff;
			}
		}
		.pos-vertical-center{
			.transition(0.3s,ease);
		}
	}
	.menu-vertical{
		> li{
			> a, > span{
				font-size: @h2-size;
				font-weight: 300;
				text-decoration: none;
			}
			&:not(:last-child){
				margin-bottom: @base-line-height;
			}
		}
		&:not(:last-child){
			margin-bottom: @base-line-height*2;
		}
	}
	&:not(.modal-active){
		.pos-vertical-center{
			-webkit-filter: blur(5px);
		}
	}
}

.nav-bar-fullscreen{
	a{
		color: @color-dark;
	}
}

// Desktop
@media all and (min-width: 990px){
	.nav-bar-fullscreen.nav--transparent{
		&:not(.nav--fixed){
			a{
				color: #fff;
			}
		}
	}
}

.nav-bar-sidebar{
	z-index: 99;
	width: 100%;
	padding-top: @base-line-height;
	.logo{
		max-height: @base-line-height;
	}
	&:not(.nav--fixed){
		position: absolute;
	}
	&.nav--fixed{
		.logo{
			display: none !important;
		}
		.menu-toggle{
			.box-shadow-wide;
		}
	}
	.menu-toggle{
		position: fixed;
		.box-shadow-wide;
		width: @base-line-height*2;
		height: @base-line-height*2;
		.translate3d(-@base-line-height*2,0,0);
		line-height: @base-line-height*2;
		background: #fff;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		right: 0;
	}
	.logo-module{
		position: relative;
		top: 5px;
	}
}

.nav-bar-sidebar + .side-menu{
	.pos-vertical-center{
		> .menu-vertical{
			.transition(.5s,ease);
			transition-delay: .2s;
			> li{
				> a, > span{
					.h3;
					text-decoration: none;
					&:hover{
						text-decoration: none;
					}
				}
				&:not(:last-child){
					margin-bottom: @base-line-height/2;
				}
			}
		}
	}
	&:not(.notification--reveal){
		.pos-vertical-center{
			.menu-vertical{
				.translate3d(20px,0,0);
				opacity: 0;
			}
		}
	}
}

nav.notification--reveal{
	+.main-container{
		&:before{
			opacity: 0;
			animation: fadeIn .3s ease forwards;
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.5);
			z-index: 20;
		}
	}
}

.nav-bar-logo{
	.logo{
		margin-right: @base-line-height;
	}
}

.dropdown{
	.dropdown__content{
		border-radius: 6px;
		.box-shadow;
		padding: @base-line-height/1.5 @base-line-height;
		&:not([class*='bg-']){
			border: 1px solid #ececec;
		}
		h5{
			&:not(:last-child){
				margin-bottom: @base-line-height/2;
			}
		}
		.background-image-holder{
			border-radius: 6px 0 0 6px;
		}
		>.pos-absolute[class*='col-']{
			left: 0;
			top: 0;
			height: 100%;
			position: absolute !important;
			&[data-overlay]{
				&:before{
					border-radius: 6px 0 0 6px;
				}
			}
		}
	}
}

.nav--transparent{
	&:not(.nav--fixed){
		.dropdown{
			.dropdown__content--lg{
				border: none;
			}
		}
	}
}

// Mobile
@media all and (max-width: 990px){
	.dropdown{
		[class*='col-']+[class*='col-']{
			margin-top: @base-line-height/2;
		}
		.dropdown__content{
			&:not([class*='bg-']){
				border: none;
				box-shadow: none;
			}
		}
	}
}

.menu-vertical{
	li{
		.transition(0.3s,ease);
		&:not(:hover){
			opacity: .75;
		}
		&:not(:last-child){
			margin-bottom: @base-line-height/6;
		}
		&.dropdown{
			position: relative;
			&:after{
				content: '\e80c';
				font-family: 'stack-interface';
				right: 0;
				position: absolute;
				.transition(0.3s,ease);
				display: inline-block;
				.translate3d(-10px,0,0);
			}
			&:hover{
				&:after{
					.translate3d(-5px,0,0);
				}
			}
		}
		&.separate{
			border-top: 1px solid lighten(@color-body, 50%);
			margin-top: @base-line-height/2;
			padding-top: @base-line-height/2;
		}
	}
	+h5{
		margin-top: @base-line-height;
	}
}

#my-icon {
	display: none;
}

@media all and (max-width: 1080px){
	#top-menu.hide-mobile {
		display: none !important;
	}

	#menu.transparent {
		top: 0px !important;

		#my-icon {
			display: block;
		}

		&:hover {

			.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
				background-color: @color-primary !important;
			}

		}

		button:focus {
			outline: 0 !important;
		}

		.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
			background-color: white !important;
			height: 2px !important;
		}

		.bar__module.icons {
			padding: 15px 0 20px 10px;
		}

		.bar__module {
			margin: 0px !important;
		}

		.bar__module+.bar__module {
			margin: 0px !important;
		}
	}
	#menu {

		.hide-mobile {
			display: none !important;
		}

		#my-icon {
			display: block;
		}

		.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
			background-color: @color-primary !important;
			height: 2px !important;
		}

		&:hover {

			.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
				background-color: @color-primary-1 !important;
			}

		}

		button:focus {
			outline: 0 !important;
		}


		.bar__module.icons {
			padding: 15px 0 20px 10px;
		}

		.bar__module {
			margin: 0px !important;
		}

		.bar__module+.bar__module {
			margin: 0px !important;
		}

		nav#menu:hover .icons {
			padding: 15px 0 23px 10px !important;
		}
	}
}

@media all and (max-width: 1024px){

	#menu.transparent .bar__module.icons, #menu .bar__module.icons {
		padding: 15px 0 23px 10px !important;
	}

	#mobile-menu .mm-panel .mm-listitem a span, #mobile-menu .mm-panels .mm-listitem a span {
		left: 76px !important;
	}
	#mobile-menu .mm-panel .mm-listitem a:hover span, #mobile-menu .mm-panels .mm-listitem a:hover span {
		left: 81px !important;
	}
	#mobile-menu .mm-panel #resources a span, #mobile-menu .mm-panels #resources a span {
		left: 95px !important;
	}
	#mobile-menu .mm-panel #resources a:hover span, #mobile-menu .mm-panels #resources a:hover span {
		left: 100px !important;
	}
	#mobile-menu .mm-panel, #mobile-menu .mm-panels {
		padding-left: 40px !important;
		padding-right: 40px !important;
	}
	#mobile-menu img#mobile-logo {
	    margin: -40px 0 0px !important;
	    padding-bottom: 0px !important;
	}

}

.main-container {
	position: relative;
	z-index: 2;
}