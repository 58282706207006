// MARKUP:
//
// <div class="hover-element">
//   <div class="hover-element__initial">
//     ..
//   </div>
//
//   <div class="hover-element__reveal">
//     ..
//   </div>
// </div>
//
//
//
// MODIFIERS:
// .hover--active - simulates a permanent hover on the element


.hover-element{
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	*{
		.transition(0.3s,ease);
	}
	.hover-element__reveal{
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		.boxed{
			height: 100%;
		}
	}
	&:hover,&.hover--active{
		.hover-element__reveal{
			opacity: 1;
		}
	}
	img{
		margin-bottom: 0;
	}
}

@media all and (max-width: 1024px){
	.hover-element{
		cursor: pointer;
	}
}

.row{
	&:last-child{
		div[class*='col-']{
			&:last-child{
				.hover-element{
					margin-bottom: 0;
				}
			}
		}
	}
}