.heightSlides(@number) when (@number > 0){
	.heightSlides((@number - 1));
	@multiplier: @number*10;
	&.height-@{multiplier}{
		height: auto;
		.slides .flickity-slider > li{
			height: @number*10vh;
			padding: 0;
		}
	}
	@media all and (min-width: 768px){
		&.height-@{multiplier}{
			.slides li.imagebg{
				min-height: @number*10vh;
			}
		}
	}
}

.slides:not(.flickity-enabled){
	li.imagebg:not(:first-child){
		display: none;
	}
	li.imagebg:first-child{
		.container{
			opacity: 0;
		}
		background: @color-dark;
		animation: backgroundLoad .5s ease alternate infinite;
	}
}

@keyframes backgroundLoad{
	0%{ background: @color-dark; }
	100%{ background: lighten(@color-dark, 10%) }
}

.slider{
	.heightSlides(10);
	.slides{
		.flickity-slider{
			> li{
				&:not([class*='col-']){
					width: 100%;
				}
			}
		}
		&.slides--gapless{
			li[class*='col-']{
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&[data-arrows="true"]{
		&.slider--arrows-hover{
			&:not(:hover){
				.flickity-prev-next-button{
					opacity: 0;
				}
			}
		}
	}
	&[data-paging="true"]{
		&:not(section){
			margin-bottom: @base-line-height*2;
			.flickity-page-dots{
				bottom: 30px;
			}
		}
		&:not([class*='text-']){
			.flickity-page-dots{
				text-align: center;
			}
		}
	}
	&[data-children="1"]{
		.flickity-prev-next-button{
			display: none;
		}
	}
	&:not([data-paging="true"]){
		.slides{
			margin: 0;
		}
	}
	&.controls--dark{
		.flickity-page-dots{
			.dot{
				background: @color-dark;
			}
		}
		.flickity-prev-next-button{
			&:before{
				color: @color-dark;
			}
		}
	}
}

section.slider{
	padding: 0;
	.heightSlides(10);
	&[data-paging="true"]{
		.flickity-page-dots{
			bottom: @base-line-height;
		}
	}
	&:not(.image--light){
		&[data-paging="true"]{
			.flickity-page-dots{
				.dot{
					background: #fff;
				}
			}
		}	
	}
	.slides{
		margin: 0;
	}
}

@media all and (max-width: 767px){
	section.slider{
		&[class*='height-']{
			.slides .flickity-slider > li{
				height: auto;
				padding: @standard-space-n 0;
			}
		}
		&.space--lg{
			.slides .flickity-slider > li{
				padding: @standard-space-mobile * 2 0;
			}
		}
		&.space--xlg{
			.slides .flickity-slider > li{
				padding: @standard-space-mobile * 2 0;
			}
		}
	}
}

section.bg--dark,section.bg--primary{
	.slider{
		&[data-paging="true"]{
			.flickity-page-dots{
				.dot{
					background: #fff;
				}
			}
		}	
	}
}

.flickity-page-dots{
	.dot{
		.transition(0.3s,ease);
		width: @slider-paging-height;
		height: @slider-paging-width;
		border-radius: @slider-paging-radius;
		background: @slider-paging-bg;
		border: @slider-paging-border;
		margin: 0 @slider-paging-spacing;
		&:hover{
			&:not(.is-selected){
				opacity: .6;
			}
		}
	}
}

.text-center, section.slider{
	.flickity-page-dots{
		text-align: center;
	}
}

.flickity-prev-next-button{
	svg{
		display: none;
	}
	&:before{
		font-family: @slider-arrow-font;
		content: @slider-arrow-right;
		font-size: @slider-arrow-size;
		font-weight: normal;
	    margin-top: 2px;
	    position: relative;
	    display: block;
	}
	&.previous{
		&:before{
			content: @slider-arrow-left;
		}
	}
}

.imagebg:not(.image--light),.bg--dark{
	.flickity-page-dots{
		.dot{
			background: #fff;
		}
	}
}