/*! -- Stack Pricing -- */

.pricing{
	.transition(0.3s,ease);
	position: relative;
	h3{
		&:first-child{
			margin: 0;
		}
	}
	.label{
		top: @base-line-height*1.5;
		right: @base-line-height*1.5;
	}
	.h2{
		margin-bottom: 0.3939393939394em;
	}
	ul{
		li{
			&:not(:last-child){
				margin-bottom: @base-line-height/4;
			}
			.checkmark{
				+span{
					position: relative;
					bottom: 3px;
				}
			}
		}
	}
	&:hover{
		.box-shadow;
	}
	.h1{
		font-size: 4.21428571428571em;
		line-height: 1.10169491525424em;
		margin-bottom: 0.22033898305085em;
		.pricing__dollar{
			font-size: 0.51020408163265em;
			position: relative;
			bottom: 20px;
		}
	}
	.feature-6{
		p{
			min-height: @base-line-height*3;
		}
	}
}

.pricing-1{
	.btn{
		&:last-child{
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			border-radius: 0 0 @button-radius @button-radius;
			padding: @base-line-height/2 0;
			margin: 0;
			&:hover{
				transform: none !important;
			}
		}
	}
	ul{
		margin-bottom: @base-line-height*3;
	}
	p{
		&:first-of-type{
			margin-top: @base-line-height;
		}
		&:last-of-type{
			margin-bottom: @base-line-height*3;
		}
		min-height: @base-line-height*3;
	}
	img{
		+p{
			&:first-of-type{
				margin-top: 0;
			}
		}
	}
}

@media all and (min-width: 991px){
	.pricing-2{
		ul{
			&:first-child{
				margin-top: @base-line-height*2.5;
			}
		}
	}
}

@media all and (max-width: 990px){
	.pricing-2{
		text-align: center;
		ul{
			&:first-child{
				margin-top: @base-line-height;
			}
		}
	}
}

.pricing-3{
	border: 1px solid #ececec;
	border-radius: 6px;
	.pricing__head{
		margin: 0;
		+.btn{
			display: block;
			border-radius: 0;
			padding: @base-line-height/2 0;
		}
		border-bottom: 1px solid #ececec;
	}
	ul{
		border-radius: 0 0 6px 6px;
		li{
			padding: @base-line-height/2 0;
			&:not(:last-child){
				border-bottom: 1px solid #ececec;
				margin: 0;
			}
		}
		&:last-child{
			margin: 0;
		}
	}
}

@media all and (max-width: 990px){
	.pricing-3{
		margin-bottom: @base-line-height;
	}
}

@media all and (min-width: 768px){
	.pricing-section-1{
		.pricing{
			&:not(.boxed--emphasis){
				left: @base-line-height/2;
			}
			&.boxed--emphasis{
				right: @base-line-height/2;
			}
		}
	}
}

@media all and (min-width: 990px){
	.pricing-section-2{
		div[class*='col-']{
			padding: 0;
			&:not(:first-child):not(:last-child){
				.pricing__head{
					border-radius: 0;
				}
			}
			&:first-child{
				.pricing{
					border-radius: 6px 0 0 6px;
					.pricing__head{
						border-radius: 6px 0 0 0;
					}
				}
			}
			&:last-child{
				.pricing{
					.pricing__head{
						border-radius: 0 6px 0 0;
					}
					border-radius: 0 6px 6px 6px;
					border-right: 1px solid #ececec;
				}
			}
		}
		.pricing{
			border: 1px solid #ececec;
			border-radius: 0 0 0 6px;
			border-right: none;
			&:hover{
				box-shadow: none;
			}
			ul{
				margin: 0;
			}
		}
	}
}

.pricing-section-2{
	&.bg--dark{
		.pricing, .pricing .pricing__head, .pricing ul li:not(:last-child), div[class*='col-']:last-child .pricing{
			border-color: lighten(@color-dark, 10%);
		}
	}
	&.imagebg{
		.pricing, .pricing .pricing__head, .pricing ul li:not(:last-child), div[class*='col-']:last-child .pricing{
			border-color: rgba(255,255,255,.2);
		}
	}
}