.centered > div {
    float: none;
    display: inline-block;
    text-align: left;
    font-size: 13px;
}

/*! -- Stack Cards -- */

.cta-box {
	border: 1px solid #eee;
	padding: 75px;

	img {
		width: 75px;
	}

	h2 {
		font-size: 32px;
		line-height: 38px;
		color: @color-primary;
		font-weight: 400;
	}

	p {
		font-size: 16px;
		letter-spacing: 0;
		line-height: 25px;
	}

	&.blue {
		background-color: @color-primary;
		h2, p {
			color: white;
		}
	}
	@media all and (max-width: 767px){ 
		padding: 50px !important;
	}
}



.card__top{
	position: relative;
	overflow: hidden;
	text-transform: uppercase;
}

.card__action{
	a{
		color: @color-body;
		i{
			color: @color-dark;
			font-size: 1em;
		}
		span{
			position: relative;
			bottom: 2px;
		}
		text-decoration: none;
		.transition(0.3s,ease);
		&:not(:hover){
			opacity: .5;
		}
	}
}

.card p a{
	text-decoration: none;
}

.masonry__item{
	.card.boxed{
		margin-bottom: 0;
	}
}

.card-1{
	.card__avatar{
		img{
			max-height: @base-line-height*1.5;
			margin-right: @base-line-height/4;
			margin-bottom: 0;
		}
		margin-bottom: @base-line-height/2;
	}
	.card__avatar, .card__meta{
		display: inline-block;
	}
	.card__meta{
		float: right;
	}
	.card__body{
		img{
			margin-bottom: @base-line-height/2;
		}
		h4{
			margin-bottom: 0.342105263157895em;
		}
		border-bottom: 1px solid #ececec;
		padding-bottom: @base-line-height/2;
		margin-bottom: @base-line-height/2;
	}
}

.bg--dark{
	.card-1{
		.card__body{
			border-color: lighten(@color-dark, 10%)
		}
	}
}

@media all and (max-width: 767px){
	.card{
		.list-inline:not(.social-list){
			li{
				display: inline-block;
				&:not(:first-child){
					margin-left: @base-line-height/2;
				}
			}
		}
	}
}

.card-2{
	.card__top{
		border: 1px solid #ececec;
		border-bottom: none;
		font-size: 16px;
		color: @color-primary;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: @base-line-height;
		padding-top: @base-line-height;
		img{
			border-radius: 6px 6px 0 0;
		}
	}
	.card__body{
		h4{
			margin: 0;
			font-size: 36px;
			color: @color-primary-1;
			letter-spacing: 1px;
			text-align: center;
		}
		p{
			margin-top: @base-line-height/2;
		}
		border-bottom: 1px solid #ececec;
		padding-bottom: @base-line-height;
	}
	.card__bottom{
		overflow: hidden;
		p {
			padding: 0px;
			margin: 0px;
		}
		> div{
			display: inline-block;
			width: 50%;
			float: left;
		}
		.h6{
			margin-bottom: 0.541666666666667em;
		}
	}
	.card__body, .card__bottom{
		padding: @base-line-height;
		border: 1px solid #ececec;
	}
	.card__body{
		border-bottom: none;
		border-top: none;
	}
	.card__bottom{
		border-radius: 0 0 6px 6px;
		padding: @base-line-height/2 @base-line-height;
		font-size: .9em;
	}
	.card__action{
		i{
			font-size: @h3-size;
		}
	}
}

.bg--dark{
	.card-2{
		.card__body, .card__bottom{
			border-color: lighten(@color-dark, 10%)
		}
	}
}