/*! -- Stack Animations -- */

@keyframes fadeInUp{
	from{ opacity: 0; .translate3d(0,50px,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased;  }
}

@-webkit-keyframes fadeInUp{
	from{ opacity: 0; .translate3d(0,50px,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased;  }
}

@keyframes fadeInDown{
	from{ opacity: 0; .translate3d(0,-100px,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased; }
}

@-webkit-keyframes fadeInDown{
	from{ opacity: 0; .translate3d(0,-100px,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased;  }
}

@keyframes fadeOutUp{
	from{ opacity: 1; .translate3d(0,0,0); }
	to{ opacity: 0;  .translate3d(0,-50px,0); -webkit-font-smoothing: antialiased;  }
}

@-webkit-keyframes fadeOutUp{
	from{ opacity: 1; .translate3d(0,0,0); }
	to{ opacity: 0;  .translate3d(0,-50px,0); -webkit-font-smoothing: antialiased;  }
}

@keyframes fadeOutRight{
	from{ opacity: 1; .translate3d(0,0,0); }
	to{ opacity: 0;  .translate3d(50px,0,0); -webkit-font-smoothing: antialiased; }
}

@-webkit-keyframes fadeOutRight{
	from{ opacity: 1; .translate3d(0,0,0); }
	to{ opacity: 0;  .translate3d(50px,0,0); -webkit-font-smoothing: antialiased; }
}

@keyframes fadeInLeft{
	from{ opacity: 0; .translate3d(-50px,0,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased; }
}

@-webkit-keyframes fadeInLeft{
	from{ opacity: 0; .translate3d(-50px,0,0); }
	to{ opacity: 1;  .translate3d(0,0,0); -webkit-font-smoothing: antialiased; }
}

@keyframes pulse{
	0%{ opacity: 0; .scale(1); }
	50%{ opacity: 1; }
	100%{ opacity: 0; .scale(2); }
}

@-webkit-keyframes pulse{
	0%{ opacity: 0; .scale(1); }
	50%{ opacity: 1; }
	100%{ opacity: 0; .scale(2); }
}

@keyframes kenBurns{
	0%{ transform: scale(1); }
	100%{ transform: scale(1.1); }
}

@-webkit-keyframes kenBurns{
	0%{ transform: scale(1); }
	100%{ transform: scale(1.1); }
}