/*! -- Stack Links -- */

a{
	color: @color-primary;
	font-weight: 700;
	&:hover{
		color: darken(@color-primary,10%);
		text-decoration: underline;
	}
	&.block{
		font-weight: normal;
		text-decoration: none;
		color: @color-body;
	}
}

p a, span a, label a{
	font-size: 1em;
	text-decoration: underline;
	font-weight: 400;
	line-height: @base-line-height;
}

p+a:not(.btn){
	font-size: @h6-size;
	line-height: @h6-line-height;
}

.imagebg:not(.image--light){
	a{
		color: #fff;
		font-weight: 600;
	}
}