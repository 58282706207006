/*! -- Stack Typography -- */

.amiri {
	font-family: 'Amiri', serif;
	color: @color-primary;
	font-weight: 400;
	font-style: italic;
	font-size: 32px;
}

.masonry-content {
	ul {
		margin: 0px;
		padding: 0;
		border: none;
		width: 100%;
		display: block;
		li {
			display: inline-block;
			text-align: left;
			font-size: 1.1em;
			color: @color-primary;
			width: auto;
			padding-right: 40px;
			font-weight: 400;
		}
	}
	@media all and (max-width: 767px){ 
		margin-bottom: @base-line-height;
	}
}

.horizontal-list {
	position: absolute;
	bottom: -7px;
	right: 15px;
	font-size: 16px;
    letter-spacing: 1px;
    text-align: left;
    font-weight: 400;
    margin: 0;
    color: @color-primary;
    label {
    	color: @color-primary;
    }
    @media all and (max-width: 767px){ 
		position: relative;
		text-align: left !important;
		right: 0px;
		bottom: 0px;
	}

	// .list-item {
	// 	position: relative;
	// 	background-image: linear-gradient(to right,#000 12%,rgba(255,255,255,0) 0);
	//     background-position: center;
	//     background-size: 10px 1px;
	//     background-repeat: repeat-x;
	// }

	// label {
	// 	font-weight: 500;
	// 	margin-bottom: 0px;
	// 	background: white;
	// }

	// span {
	// 	color: @color-primary-1;
	// 	display: inline-block;
	// 	float: right;
	// 	background: white;
	// }
}

.content-block {
	padding-bottom: @base-line-height;
	hgroup {
		h1 {
			font-size: 40px;
			color: @color-primary;
			font-weight: 300;
			letter-spacing: -1px;
			line-height: 48px;
			margin: 0px;
			padding: 0px;
		}

		h2 {
			font-size: 20px;
			color: @color-primary-1;
			letter-spacing: 1px;
			text-align: left;
			font-weight: 400;
			line-height: 30px;
			margin: 0px;
			padding: 0px;
		}
	}

	h2 {
		font-size: 20px;
		color: @color-primary;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: @base-line-height/2;
	}

	p {
		font-size: 14px;
	}

	ul {
		li {
			font-size: .85714286em;
			color: #3D3D3D;
			line-height: 36px;
			position: relative;
			background: white;
			z-index: 2;
			strong {
				background: white;
				font-weight: normal;
				position: relative;
				z-index: 3;
				display: inline-block;
				padding-right: 5px;
			}
			// background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 20%);
		 //    background-position: center;
		 //    background-size: 10px 1px;
		 //    background-repeat: repeat-x;
		    &:before {
		    	content: '';
		    	position: absolute;
		    	left: 0px;
		    	top: 0px;
		    	width: 100%;
		    	height: 100%;
				display: block;
				background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 20%);
			    background-position: center;
			    background-size: 10px 1px;
			    background-repeat: repeat-x;
			    z-index: 1;
		    }

		    &:after {
		    	content: '\f560';
		    	font-family: "Font Awesome 5 Pro"; 
		    	color: @color-primary-1;
		    	position: absolute;
		    	right: 0px;
		    	top: 0px;
		    	z-index: 3;
		    	background-color: white;
		    	padding-left: 5px;

		    }
		}
	}
}

.tab__content {
	margin-top: 25px;
	&.big-list {
		ul{
			padding-right: @base-line-height;
		}
	}
	h3 {
		font-size: 20px;
		color: @color-primary;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: @base-line-height/2;
	}
	h4 {
		font-size: 16px;
		color: @color-primary-1;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: @base-line-height/4;
	}
	p {
		font-size: 14px;
	}
}

.content-list {
	padding-bottom: @base-line-height;
	h3 {
		font-size: 20px;
		color: @color-primary;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: @base-line-height/4;
	}

	ul {
		li {
			font-size: 16px;
			color: #3D3D3D;
			line-height: 36px;
			width: 100%;
			display: block;
			background-image: linear-gradient(to right, #000 12%, rgba(255, 255, 255, 0) 0%);
		    background-position: center;
		    background-size: 10px 1px;
		    background-repeat: repeat-x;
			label {
				background: white;
				padding-right: 1em;
			}
			span{
				font-size: 16px;
				background: white;
				color: #D0021B;
				float: right;
				padding-left: 1em;
				line-height: 36px;
			}
		}
	}
}

@media all and (max-width: 768px){
	.content-list {
		ul {
			li {
				line-height: 25px;
			}
		}
	}
}

@media all and (max-width: 1024px){
	html{
		font-size: 80%;
	}
}

h1,.h1{
	&:not(:last-child){
		margin-bottom: 0.59090909090909em;
	}
	letter-spacing: -.01em;
}

@media all and (min-width: 768px){
	h1,.h1{
		&.h1--large{
			font-weight: 200;
			font-size: 4.428571428571429em;
			line-height: 1.048387096774194em;
			&:not(:last-child){
				margin-bottom: 0.419354838709677em;
			}
			&.type--uppercase{
				letter-spacing: 10px;
				margin-right: -10px;
			}
			+p.lead{
				margin-top: 2.052631578947368em;
			}
		}
	}
}

h2,.h2{
	margin-bottom: 0.78787878787879em;
}

h3,.h3{
	margin-bottom: 1.04em;
	strong{
		font-weight: 400;
	}
}

blockquote{
	&:not(:last-child){
		margin-bottom: 1.04em;
	}
	font-family: 'Merriweather', serif;
	font-style: italic;
	font-weight: 300;
	> p{
		font-size: 1em !important;
	}
}

h4,.h4{
	margin-bottom: 1.36842105263158em;
	font-weight: 400;
	&.inline-block{
		+.h4{
			&.inline-block:not(.typed-text){
				margin-left: 0.68421052631579em;
			}
		}
	}
}

h5,.h5{
	font-weight: 600;
	&:not(:last-child){
		margin-bottom: @base-line-height;
	}
}

h6,.h6{
	&:not(:last-child){
		margin-bottom: 2.16666666666667em;
	}
	font-weight: 700;
	&.type--uppercase{
		letter-spacing: 1px;
		margin-right: -1px;
	}
}

span{
	&.h1,&.h2,&.h3,&.h4,&.h5,&.h6{
		&:not(.inline-block){
			display: block;
		}
	}
}

b{
	font-weight: 600;
}

hr{
	border-color: #ECECEC;
}

.bg--dark{
	hr{
		border-color: lighten(@color-dark, 20%)
	}
}

[class*='bg-']:not(.bg--white):not(.bg--secondary), [class*='imagebg']:not(.image--light){
	p{
		opacity: .9;
	}
}

.lead{
	font-weight: 400;
	color: lighten(@color-body, 10%);
	&:not(:last-child){
		margin-bottom: 1.36842105263158em;
	}
	+.btn{
		&:last-child{
			margin-top: @base-line-height/2;
		}
	}
}

p{
	&:last-child{
		margin-bottom: 0;
	}
	strong{
		color: @color-dark;
	}
}

pre{
	padding: @base-line-height/2;
	background: @color-bg-secondary;
	border: 1px solid #ececec;
	border-radius: 6px;
	line-height: 20px;
	max-height: 500px;
}

.bg--secondary{
	> pre{
		background: darken(@color-bg-secondary, 2%);
		border-color: #ddd;
	}
}

.text-block{
	margin-bottom: @base-line-height;
	h2,.h2{
		margin-bottom: 0.3939393939394em;
	}
	h5,.h5{
		margin: 0;
	}
	h4,.h4{
		&:not(:last-child){
			margin-bottom: 0.3421052631579em;
		}
	}
	h3,.h3{
		margin-bottom: 0.52em;
	}
}

@media all and (min-width: 768px){
	div[class*='col-']{
		.text-block{
			+.text-block{
				margin-top: @base-line-height*2;
			}
		}
	}
}

.heading-block{
	margin-bottom: @base-line-height*2;
	h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
		margin-bottom: 0;
	}
}