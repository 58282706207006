/*! -- Stack Bars -- */

.menu-horizontal > li, .menu-vertical > li{
	font-family: @nav-item-font, 'Roboto', 'Helvetica', Sans-Serif;
}

.dropdown .dropdown__content {
	border-radius: 0px !important;
	box-shadow: 0 10px 34px 0 rgba(0,0,0,.2) !important;
	border: none !important;
}

.bar{
	padding: 0px;
	.logo{
		max-height: @logo-max-height;
		max-width: none;
	}
}

.menu-horizontal{
	> li{
		> a, > .modal-instance > a{
			font-weight: 500;
		}
		a{
			&:hover{
				text-decoration: none;
			}
		}
	}
}

.hamburger-toggle{
	i{
		color: @color-dark;
	}
}

nav#menu {
	transition: all 0.3s ease;
	.dropdown--active > span {
		color: @color-primary-1 !important;
	}
	&:hover {
		background-color: white !important;
		.brand {
			.logo-regular {
				display: block;
			}
			.logo-white {
				display: none;
			}
		}
		.icons {
			padding:15px 0;
			.logo-regular {
				display: inline-block;
			}
			.logo-white {
				display: none;
			}
		}
		.bar__module {
			li > a, li span {
				color: @color-primary !important;
				&:hover {
					color: @color-primary-1 !important;	
				}
			}
			.dropdown--active > span {
				color: @color-primary-1 !important;
			}
		}
	}
}

.menu-horizontal{
	li{
		&:not(:last-child){
			margin-right: @base-line-height*2;
		}
	}
}
.bar__module{
	padding: 22px 0 18px;
	&:not(:only-child){
		.menu-horizontal{
			top: 0;
		}
	}
	&.icons{
		float: right;
		border-left: 1px solid @border-color;
		padding: 15px 0;
		li{
			
			padding: 0 20px;
			display: inline-block;
			list-style: none;
			a {
				display: inline-block;
			}
		}
	}
	&.brand {
		padding: 10px 0;
		max-width: 230px;
		a {
			display: block;
		}
	}
}


@media all and (min-width: 768px) and (max-width: 1023px){
	.bar-2, .bar-1{
		.menu-horizontal{
			> li{
				display: inline-block;
				&:not(:first-child){
					margin-left: @base-line-height/2;
				}
			}
		}
		.bar__module{
			margin-bottom: @base-line-height;
		}
		.row{
			div[class*='col-']{
				&:last-child{
					.bar__module{
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.bar-1{
	.menu-horizontal{
		> li{
			> .dropdown__trigger, > a{
				font-size: 0.857142857142857em;
				line-height: 2.166666666666667em;
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: .5px;
			}
		}
	}
}

// Desktop
@media all and (min-width: 1024px){
	.bar-1{
		.bar__module{
			+.bar__module{
				margin-left: @base-line-height;
			}
		}
	}
}

@media all and (min-width: 991px){
	.bar-2{
		.logo{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.bar-3{
	font-size: @fine-print-size;
	.menu-horizontal{
		top: 0;
	}
}

@media all and (max-width: 990px){
	.bar-3{
		.menu-horizontal{
			li{
				display: inline-block;
				&:not(:last-child){
					margin-right: @base-line-height/2;
				}
			}
		}
	}
}

// Desktop
@media all and (min-width: 990px){
	.bar-toggle{
		.col-md-1{
			.logo{
				top: 10px;
			}
		}
	}
}

@media all and (max-width: 767px){
	.bar-4{
		.logo-light{
			display: none;
		}
		.logo-dark{
			margin-bottom: @base-line-height;
		}
	}
	.bar.bg--dark{
		background: @color-dark;
		.hamburger-toggle i{
			color: #fff;
		}
	}
}