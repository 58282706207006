/*! -- Stack Nav Utility -- */

.nav-utility{
	padding: @base-line-height/2 0;
	.nav-utility__module{
		font-size: 0.85714285714286em;
		a{
			color: @color-body;
			font-weight: normal;
			i{
				.transition(0.3s,ease);
				display: inline-block;
			}
			&:hover{
				i{
					.scale(1.5);
				}
			}
		}
		&.right{
			&:not(:last-child){
				margin-left: 2.16666666666667em;
			}
		}
	}
}

.utility-toggle{
	display: inline-block;
	cursor: pointer;
	width: 3px;
	height: 3px;
	position: relative;
	bottom: 8px;
	border-radius: 50%;
	background: @color-dark;
	&:before, &:after{
		content: '';
		width: 3px;
		height: 3px;
		border-radius: 50%;
		position: absolute;
		background: @color-dark;
	}
	&:before{
		top: 6px;
	}
	&:after{
		top: 12px;
	}
}