// MARKUP:
//
// <div class="masonry">
//   <div class="masonry__item col-*">
//       ...
//   </div>
// </div>
//
// MODIFIERS:
// .modal-trigger[data-modal-id] - use any element to trigger a modal using its ID
// [data-autoshow] - place on '.modal-container' outside the main container for autoshow modals
// [data-width][data-height] - place on modal content to control height in %

.home-feature {
	width: 100%;
}

.featured-ad {
	position: relative;
}

.featured-body {
	position: absolute;
	width: 100%;
	bottom: 0px;
	left: 0px;
	right: 0px;
	h3 {
		font-size: 20px;
		text-transform: uppercase;
		text-align: center;
	}
	p {
		color: white;
		padding: 0px !important;
	}
	span {
		border-radius: 1000px !important;
		border: 2px solid #fff;
		text-align: center;
		display: block;
		font-size: 16px;
		color: white;
	}
}

.masonry, .home-feature {
	.yacht_image {
		height: 235px;
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 15px;
	}
	h5 {
		font-weight: 500;
		margin: 0px !important;
		padding: 0px !important;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: 1.2em;
		color: @color-primary-1;
	}
	h6 {
		font-weight: 400;
		margin: 0px !important;
		padding: 0px !important;
		letter-spacing: 1px;
		font-size: .8em;
		color: @color-primary;
	}
	ul {
		margin: 0px;
		padding: 10px 0 15px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		width: 100%;
		display: block;
		li {
			display: inline-block;
			text-align: center;
			font-size: 1.2em;
			color: @color-primary;
			width: 24%;
			font-weight: 400;
			position: relative;
			small {
				position: absolute;
				font-size: 10px;
				color: #777;
				left: 0px;
				right: 0px;
				bottom: -20px;
			}
		}
	}
	.masonry_link {
		display: block;
		box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
		-webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
		-webkit-transition: all .2s ease;
    	transition: all .2s ease;
		&:hover {
			-webkit-box-shadow: 0 10px 34px 0 rgba(0,0,0,.2);
		    box-shadow: 0 10px 34px 0 rgba(0,0,0,.2);
		    text-decoration: none;
		    transform: translateY(-5px);
		}
	}
	.masonry__container{
		&.masonry--active{
			.masonry__item{
				opacity: 1;
				pointer-events: all;
			}
		}
		.masonry__item{
			opacity: 0;
			pointer-events: none;
		}
	}
	.masonry__filters{
		li{
			display: inline-block;
			cursor: pointer;
			text-transform: capitalize;
			.disable-select();
			&.active{
				cursor: default;
			}
		}
	}
	&.masonry--gapless{
		.masonry__item{
			padding: 0 !important;
			margin-bottom: 0;
		}
	}
}