/*! -- Stack Processes -- */

.process-1{
	padding: @base-line-height*2 0;
	position: relative;
	overflow: hidden;
	.process__item{
		width: 50%;
		&:not(:last-child){
			margin-bottom: @base-line-height;
		}
	}
	&:before{
		content: '';
		position: absolute;
		width: 2px;
		height: 100%;
		left: 50%;
		top: 0;
		background: @color-primary;
	}
}

.process__item{
	position: relative;
	&:after{
		content: '';
		width: @base-line-height;
		height: @base-line-height;
		background: #fff;
		border: 2px solid @color-primary;
		border-radius: 50%;
		position: absolute;
		top: 0;
		margin-left: 1px;
	}
}

@media all and (min-width: 768px){
	.process-1{
		.process__item{
			&:nth-child(odd){
				text-align: right;
				padding-right: @base-line-height*2;
				&:after{
					right: -@base-line-height/2;
				}
			}
			&:nth-child(even){
				margin-left: 50%;
				padding-left: @base-line-height*2;
				&:after{
					left: -@base-line-height/2;
				}
			}
		}
	}
}

@media all and (max-width: 767px){
	.process-1,.process-2{
		&:before{
			left: @base-line-height/2;
		}
		.process__item{
			width: 100%;
			padding-left: @base-line-height*2;
			&:after{
				left: 0;
			}
		}
	}
}

.bg--dark, .imagebg{
	.process-1, .process-2{
		&:before{
			background: #fff;
		}
		.process__item{
			&:after{
				background: #fff;
			}
		}
	}
}

.bg--dark{
	.process-1, .process-2{
		.process__item{
			&:after{
				border-color: @color-dark;
			}
		}
	}
}

.process-2{
	position: relative;
	.process__item{
		padding-top: @base-line-height*2;
		&:after{
			display: inline-block;
			text-align: center;
		}
	}
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		top: @base-line-height/2;
		left: 0;
		background: @color-primary;
	}
}

@media all and (max-width: 767px){
	.process-2{
		&:before{
			height: 100%;
			width: 2px;
			left: @base-line-height;
			margin-left: 2px;
		}
		.process__item{
			&:after{
				top: @base-line-height*2;
			}
		}
	}
}

.process-3{
	list-style: none;
	position: relative;
	li{
		position: relative;
		z-index: 1;
		overflow: hidden;
		.process__number{
			float: left;
			width: 15%;
			width: @base-line-height *2;
			height: @base-line-height * 2;
			border-radius: 50%;
			border: 2px solid @color-primary;
			background: #fff;
			text-align: center;
			span{
				font-size: @h4-size;
				font-weight: bold;
				color: @color-primary;
				position: relative;
				top: 12px;
			}
		}
		.process__body{
			width: 80%;
			float: right;
			h4{
				margin-bottom: 0.342105263157895em;
			}
		}
		&:not(:last-child){
			margin-bottom: @base-line-height * 2;
		}
	}
	&:before{
		content: '';
		position: absolute;
		height: 100%;
		width: 1px;
		left: @base-line-height;
		top: 0;
		border-left: 1px dashed #ddd;
	}
}

.bg--dark{
	.process-3{
		li{
			.process__number{
				background: @color-dark;
			}
		}
		&:before{
			border-color: #aaa;
		}
	}
}

.process-stepper{
	> li{
		transition: all .3s ease;
		display: inline-block;
		float: left;
		text-align: center;
		position: relative;
		&:before{
			transition: all .3s ease;
			content: '';
			width: @base-line-height/2;
			height: @base-line-height/2;
			background: #ccc;
			border-radius: 50%;
			display: block;
			margin: 0 auto;
			margin-bottom: @base-line-height/2;
		}
		&:after{
			transition: all .3s ease;
			content: '';
			width: 100%;
			height: 2px;
			position: absolute;
			background: #ccc;
			top: 5px;
			left: 0;
		}
		&:first-child, &:last-child{
			&:after{
				width: 50%;
			}
		}
		&:first-child{
			&:after{
				left: 50%;
			}
		}
		&.active{
			&:before, &:after{
				background: @color-primary;
			}
		}
		> span{
			cursor: default;
		}
	}
	&[data-process-steps="6"]{
		> li{
			width: 16.666666666666667%;
		}
	}
	&[data-process-steps="5"]{
		> li{
			width: 20%;
		}
	}
	&[data-process-steps="4"]{
		> li{
			width: 25%;
		}
	}
	&[data-process-steps="3"]{
		> li{
			width: 33.33333333%;
		}
	}
	&[data-process-steps="2"]{
		> li{
			width: 50%;
		}
	}
}

.bg--dark{
	.process-stepper{
		li{
			&:not(.active){
				&:before, &:after{
					background: lighten(@color-dark, 20%)
				}
			}
		}
	}
}