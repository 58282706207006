/*! -- Stack CTA -- */

.cta{
	.btn{
		&:first-child{
			&:not(:last-child){
				margin-bottom: @base-line-height;
			}
		}
	}
	&.boxed{
		&:last-child{
			margin-bottom: 0;
		}
	}
}

@media all and (min-width: 768px){
	.cta--horizontal{
		h4,.lead,.btn{
			margin: 0;
		}
		h4,.lead{
			position: relative;
			top: 4px;
		}
	}
	.bg--dark,.imagebg{
		.cta-1{
			>div[class*='col-']{
				&:last-child{
					border-left: none;
				}
			}
		}
	}
}

@media all and (min-width: 1200px){
	.cta-1{
		&.boxed--border{
			padding: 0;
		}
		>div[class*='col-']{
			padding: @base-line-height/1.5 15px;
			&:last-child{
				border-left: 1px solid #ECECEC;
			}
		}
	}
}

@media all and (max-width: 990px){
	.cta-1{
		text-align: center;
		>div[class*='col-']{
			padding-left: 0;
			padding-right: 0;
			&:not(:last-child){
				margin-bottom: @base-line-height/2;
			}
			&:last-child{
				margin-top: @base-line-height;
			}
		}
	}
}

@media all and (max-width: 767px){
	.cta{
		.lead{
			margin: 1.36842105263158em 0;
		}
	}
}

.cta-2{
	.transition(0.3s,ease);
	h2{
		margin: 1.57575757575758em 0;
	}
	&.bg--primary{
		&:hover{
			background: lighten(@color-primary, 3%)
		}
	}
	&:hover{
		background: lighten(@color-bg-site, 3%)
	}
}

@media all and (min-width: 768px){
	.cta-3{
		.switchable__text{
			margin-top: @base-line-height*3;
		}
	}
}