/*! -- Stack Colours -- */

.bg--dark{
	.bg--secondary{
		background: lighten(@color-dark, 6%)
	}
}

.w-md-auto {
	@media all and (min-width: 768px){
		width: auto !important;
	}
}

.text-dark-blue {
	color: #090c5b;
}

.text-dark-red {
	color: #d0021b;
}


.bg-dark-blue {
	background-color: #090c5b;
}

.rounded-fleet {
	border-radius: 50rem !important;
}

.masonry-filter-container {
	a {
		border-width: 2px !important;
		&:hover, &.active {
			border: 2px solid #090c5b !important;
			text-decoration: none !important;
		}
	}
}

.cursor {
	cursor: pointer !important;
}