/*! -- Stack Accordions -- */
.wp-block-faq-block-for-gutenberg-faq {
	background-color: white;
	padding: 0px;
	&:last-of-type {
		margin-bottom: 3.5em;
	}
	.question {
		box-shadow: 0 0 5px #92cffd;
		border: none !important;
		transition: all 0.3s ease;
		position: relative;
		border-radius: 6px;
		padding: .5em;
		h4 {
			display: inline-block;
			color: @color-primary;
			font-size: 1em;
			margin: 0px;
			padding: 0px;
		}
		&:hover {
			box-shadow: 0 0 5px @color-primary-1;
		}
		&.active {
			box-shadow: 0 0 5px @color-primary-1;
		}
		&:first-child {
			&:before {
				font-weight: normal;
    			color: @color-primary;
			}
		}
	}
	.answer {
		padding: 1em !important;
		font-size: .9em;
		margin: 0px;
		background-color: white;
		color: #666;
		font-weight: 400;
		a {
			font-weight: normal;
		}
	}
}


.accordion{
	.accordion__title{
		box-shadow: 0 0 5px #92cffd;
		border: none !important;
		transition: all 0.3s ease;
		position: relative;
		// opacity: .5;
		// margin-bottom: @base-line-height/2;
		&:hover{
			box-shadow: 0 0 5px @color-primary-1;
		}
		// &:before{
		// 	font-family: "stack-interface";
		// 	content: '\e80d';
		// 	margin-right: 6px;
		// 	color: @color-dark;
		// }
		i {
			font-size: .8em;
		    color: @color-primary;
		    position: absolute;
		    top: 50%;
		    right: 15px;
		    transform: translateY(-50%);
		    transition: all .3s ease;

		    &.fa-minus {
		    	display: none;
		    }
		}
		span{
			display: inline-block;
			color: @color-primary;
		}
	}
	li{
		margin-bottom: 1em;
		&.active{
			margin-bottom: @base-line-height/2;
			.accordion__title{
				i.fa-minus {
					display: block;
				}
				i.fa-plus {
					display: none;
				}
				opacity: 1;
				&:before{
					// content: '\e80a';
				}
			}
			.accordion__content{
				p {
					padding: 1em !important;
					font-size: .9em;
					margin: 0px;
				}
				// animation: fadeUp .5s ease forwards;
				// -webkit-animation: fadeUp .5s ease forwards;
			}
		}
	}
}

.accordion-1{
	li{
		&.active{
			.accordion__title{
				span{
					color: @color-primary;
				}
				box-shadow: 0 0 5px @color-primary-1;
				background-color: white;
			}
		}
		.accordion__title{
			border-radius: 6px;
		}
	}
}

.accordion-2{
	li{
		.accordion__title{
			border-left: none;
			border-right: none;
			border-top: none;
			border-bottom: 1px solid #ECECEC;
			padding-left: 0;
		}
		&.active{
			.accordion__title{
				background: none;
				border-bottom: 1px solid rgba(0,0,0,0);
			}
		}
	}
}

.bg--dark,.imagebg:not(.image--light), .bg--primary{
	.accordion-2, .accordion-1{
		li{
			.accordion__title{
				&:before{
					color: #fff;
				}
				border-color: lighten(@color-dark, 15%)
			}
		}
	}
}

.imagebg:not(.image--light), .bg--primary{
	.accordion{
		li{
			.accordion__title{
				border-color: rgba(255,255,255,.3);
			}
		}
	}
}