/*! -- Stack Helpers -- */

html, body {
    height: 100%;
    position: relative;
}

.red {
    color: @color-primary-1 !important;
}

.size42 {
    font-size: 42px !important;
    margin-bottom: 15px !important;
}

.guide-nav {
    margin-top: 25px;
}

.popover {
    transform: inherit !important;
    z-index: 1000;

    @media all and (max-width: 1200px){ 
        display: none !important;
    }

}

.popover-template {
    width: 300px !important;
    background: white !important;
    box-shadow: 0 0 35px rgba(0,0,0,.5)!important;
    min-height: 200px !important;
    border-radius: 10px;
    z-index: 10;
    &.anegoda-island { // Anagada
        transform: translate3d(737px, -185px, 0px) !important;
    }
    &.cooper-island {
        transform: translate3d(485px,335px,0)!important;
    }
    &.dog-island {
        transform: translate3d(610px,43px,0)!important;
    }
    &.guana-island {
        transform: translate3d(332px,103px,0)!important;
    }
    &.jost-van-dyke-island {
        transform: translate3d(-50px,173px,0)!important;
    }
    &.norman-island {
        transform: translate3d(250px,487px,0)!important;
    }
    &.peter-island {
        transform: translate3d(326px,399px,0)!important;
    }
    &.salt-island {
        transform: translate3d(450px,350px,0)!important;
    }
    &.tortola-island {
        transform: translate3d(240px,190px,0)!important;
    }
    &.virgin-gorda-island {
        transform: translate3d(750px,50px,0)!important;
    }
    &:before {
        border-top: 7px solid rgba(255,255,255,255.85);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        bottom: -7px;
        content: '';
        display: block;
        left: 50%;
        margin-left: -7px;
        position: absolute;
    }
    h3 {
        display: none !important
    }
    img {
        width: 100%;
        margin: 0px;
        border-radius: 10px 10px 0 0;
    }
    #small-icon {
        width: 20px;
        border-radius: 0;
        margin-right: 10px;
    }
    #popover-bottom {
        display: block;
        padding: 10px 15px;
        position: relative;
        small {
            font-size: .8em;
            color: @color-primary;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        a {
            color: @color-primary-1;
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }
}

.about-top {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 2em 0 2.5em;
    .about-space {
        h4 {
            font-family: 'Amiri', serif;
            font-size: 24px;
            color: @color-primary;
            letter-spacing: 4px;
            margin: 0px;
            padding: 0px;
            line-height: 46px;
        }
        h5 {
            font-weight: 400;
            text-transform: uppercase;
            color: @color-primary-1;
            letter-spacing: 2px;
        }
    }
}

.history-item {
    margin-bottom: 150px;
    h5 {
        font-size: 20px;
        font-weight: 400;
        color: @color-primary;
        letter-spacing: 2px;
        line-height: 40px;
        text-transform: uppercase;
        margin: 0px !important;
        padding: 0px;
    }

    i {
        font-family: 'Amiri', serif;
        font-style: italic;
        color: @color-primary-1;
        font-size: 16px;
        line-height: 20px;
        width: 50%;
        margin: 0 auto 15px;
        display: block;
    }
}

.meet-vcy {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 3em 0 3.5em;

    h5 {
        font-size: 20px;
        font-weight: 400;
        color: @color-primary;
        letter-spacing: 2px;
        line-height: 40px;
        text-transform: uppercase;
        margin: 0px 0 2em !important;
        padding: 0 30px;
        background: #fff;
        display: inline-block;
    }

    .space-up {
        margin-top: -68px;
    }
}

.team-member {
    h5 {
        font-size: 24px;
        margin: 0px !important;
        padding: 0px !important;
        color: @color-primary;
        font-family: 'Amiri', serif;
        line-height: 24px;
        font-weight: 400;
    }
    h6 {
        font-size: 14px;
        color: @color-primary-1;
        margin: 0px 0 10px !important;
        padding: 0px !important;
        font-weight: 400;
    }
}

#hideAll
 {
   position: fixed;
   left: 0px; 
   right: 0px; 
   top: 0px; 
   bottom: 0px; 
   background-color: white;
   z-index: 99; /* Higher than anything else in the document */

 }

.page-section {
    padding-top: 25px;
}

#heroSized {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &:after {
        //9e9e9e;
        content:'';
        // background-color: @color-primary;
        background-image:
        linear-gradient(
          to bottom, 
          #9e9e9e, 
          rgba(255, 255, 255, 0), 
          #9e9e9e
        );
        mix-blend-mode: multiply;
        opacity: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        z-index: 1;
    }
}

#gradient {
    &:after {
        //9e9e9e;
        content:'';
        // background-color: @color-primary;
        background-image:
        linear-gradient(
          to bottom, 
          #9e9e9e, 
          rgba(255, 255, 255, 0), 
          #9e9e9e
        );
        mix-blend-mode: multiply;
        opacity: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        z-index: 1;
    }
}

.section-head {
    h1 {
        font-size: 48px;
        color: @color-primary;
        letter-spacing: -1.85px;
        font-weight: 100;
        line-height: 58px;
        margin: 0;
        padding: 0;
    }
    p {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 28px;
    }
} 

.guide-group {
    margin-bottom: 40px; 

    @media all and (max-width: 767px){ 
        margin-bottom: 10px !important;
    }

    p {
        color: @color-primary-1;
        font-size: .8em;
        line-height: 1.5em;
        padding: 7px 20px 7px 7px;
    }
}

.guide-top {
    border-bottom: 1px solid #eee;
    margin-bottom: 75px;
}

#scroll-nav {
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    background-color: white !important;
    transition: all 0.3s ease;
    overflow-y: auto;

    .filter-title {
        cursor: pointer;
    }

    h4 {
        text-transform: uppercase;
        color: #666;
        font-size: .9em;
        margin-left: 15px;
        padding-bottom: 10px;
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        width: 100%;
        display: block;
        margin-bottom: 15px;

        @media all and (max-width: 767px){ 
            margin-left: 0px !important;
        }

        i {
            float: right;
            color: #ccc;
        }
    }

    .nav-link ~ .nav-pills {
        display: none;
    }

    .nav-link + .nav-pills {
        display: block;
        font-style: normal !important;
    }

    .nav-link {
        width: 100%;
        display: block;
        font-weight: 400;
        font-size: 1.1em;
        color: @color-primary-1;
        padding-left: 15px;
        position: relative;
        transition: all 0.3s ease;
        font-family: 'Amiri', serif;

        @media all and (max-width: 767px){ 
            padding-left: 0px;
        }

        &.first-nav-item {
            font-size: 1.3em;
        }

        &.active {
            font-style: italic;
            &:before {
                position: absolute;
                content: '';
                width: 3px;
                height: 100%;
                left: 0px;
                top: 0px;
                background-color: @color-primary;
            }
        }
    }

    .nav-pills {
        transition: all 0.3s ease;
        > .nav-link {
            font-size: .9em;
            color: @color-primary;
            line-height:1.6em;
            font-weight: 400;
        }
    }
}

.page-spacer {
    margin: 3em 0;
}

.page-section {
    h2 {
        font-size: 2.3em;
        color: @color-primary;
        font-weight: 100;
        margin: 0 0 20px;
        padding: 0;
        font-style: italic;
        font-family: 'Amiri', serif;
        letter-spacing: .5px;
    }

    h3 {
        font-size: 1.7em;
        color: @color-primary;
        font-weight: 100;
        line-height: 48px;
        margin: 0 0 5px;
        padding: 0;
    }

    h4 {
        font-size: 1.2em;
        color: @color-primary-1;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 36px;
        margin: 25px 0 5px;
        padding: 0;
    }

    p {
        line-height: 2em;
        margin-bottom: 1em;
         strong {
            color: #666;
        }
    }

    a {
        color: @color-primary-1;
    }
    
    ol {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        counter-reset:li;
        // margin: 30px 0;
        padding: 20px 0; 

        > li {
            position:relative;
            margin:0 0 6px 3em;
            padding:4px 8px;
            list-style:none; 

            a {
                text-decoration: underline;
                color: @color-primary;
                font-weight: 500;
            }

            b {
                color: @color-primary;
                font-weight: 500;
            }

            &:before {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                top: 2px;
                left: -3em;
                border-radius: 1000px;
                width: inherit;
                height: inherit;
                padding: 0px 14px;
                border: 2px solid #e9eefa;
                color: #6e6e6e;
                text-align: center;
            }
        }
    }

    .mason-container {
        margin: 3em 0 1em;
    }

    .img-container {
        // margin:5em -100%; /* old browsers fallback */
        margin: 3em 0;

        img {
            width: 100%;
            position: relative;
            left: -100px;
        }
    }

    .slide-container {
        // margin:5em -100%; /* old browsers fallback */
        margin: 3em 0;

        .slider {
            width: 100%;
            position: relative;
            left: -100px;
        }
    }

    .callout-text {
        color: @color-primary-1;
        font-family: 'Amiri', serif;
        font-style: italic;
        font-size: 1.1em;
    }

    .callout-support {
    }

    .callout-1 {
        margin-bottom: 2em;
        .callout-text {
            margin-bottom: 1em;
            margin-left: 3em;
        }
    }

    .callout-2 {
        margin-bottom: 2em;
    }

    .callout-3 {

        margin: 3em 0;
        
        blockquote {
            width: 100%;
            display: block;
            border: 1px solid @color-primary;
            padding: 1.3em 2em;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            position: relative;
            font-size: 1em !important;

            &:after {
                background:white url('../../assets/img/anchor-red.svg') no-repeat;
                background-position: center;
                content: '';
                position: absolute;
                top: -15px;
                left: 0px;
                right: 0px;
                margin: auto;
                width: 40px;
                height: 25px;
            }

            p {
                line-height: 1.5em;
                font-size: 1em !important;
                color: #666;
                font-weight: 400;
                text-align: center;
            }

            a {
                color: @color-primary-1;
                font-weight: 500;
                font-size: .85em !important;
                text-transform: uppercase;
                text-decoration: none;
                display: inline-block;
                letter-spacing: 1px;
                margin-left: 5px;
                i {
                    font-size: .8em;
                    margin-left: 2px;
                }
            }
        }
    }


}

.stylized {
    background: white;
    h5 {
        font-family: 'Amiri', serif;
        color: @color-primary;
        font-style: italic;
        font-size: 1.5em;
        font-weight: 400;

    }
}


@media all and (max-width: 767px){
	.block--xs{
		margin-top: @base-line-height/2;
	}
}

.gray-bg {
    background-color: #f8f8f8;
}

.sans {
    font-family: 'Roboto', sans-serif !important;
}

.lowercase {
    text-transform: initial !important;
}

.container{
	.container{
		max-width: 100%;
	}
}

.switchable-toggle{
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
}

.back-to-top{
	position: fixed;
    width: @base-line-height*2;
    height: @base-line-height*2;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    right: @base-line-height;
    bottom: @base-line-height*2;
    padding-top: 12px;
    .box-shadow;
    z-index: 99;
    border: 1px solid #ececec;
    i{
    	color: @color-dark;
    }
    .transition(0.2s,ease-out);
    &:not(.active){
    	opacity: 0;
    	.translate3d(0,20px,0);
    	pointer-events: none;
    }
    &.active{
    	&:hover{
    		.translate3d(0,-5px,0);
    	}
    }
}

.disable-scroll-bars{
    -ms-overflow-style: none;
    &::-webkit-scrollbar { 
        display: none;
    }
}

.offset-1-2 {
    @media (min-width: 992px) { 
        margin-left: 12.49999995%;
    }
}

.gform_required_legend {
    display: none;
}