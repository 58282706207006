//////// Colours

@color-bg-site: #fff;
@color-primary: #080b5c;
@color-primary-1: #D0021B;
@color-primary-2: #090B5B;
@color-dark: #222;
@color-bg-secondary: #f8f8f8;
@color-on-dark: #f5f5f5;
@border-color: #e6e4e5;

@has-primary-1: true;
@has-primary-2: true;

//////// Typography

@base-font-size: percentage(16/16); // change first value to pixel font size
@base-font-size-px: 16px; // the first value from above;
@base-font-size-mobile: percentage(14/16); // change first value to pixel font size
@base-font-size-mobile-px: 14px; // the first value from above;
@base-line-height: 1.6875em;

@body-font: 'Roboto';
@heading-font: 'Roboto';
@button-font: 'Roboto';
@nav-item-font: 'Roboto';

@h1-size: 4.25em;
@h2-size: 2.625em;
@h3-size: 1.625em;
@h4-size: 1em;
@h5-size: 1em;
@h6-size: 1em;

@ligatures-active: true;

@h1-line-height: 1.171875em;
@h2-line-height: 0.9em;
@h3-line-height: 1.171875em;
@h4-line-height: 0.92em;
@h5-line-height: 1em;
@h6-line-height: 2.272272272em;

@p-lead-size: 1.4375em;
@p-lead-line-height: 0.92em;

@blockquote-size: @h3-size;
@blockquote-line-height: @h3-line-height;

@fine-print-size: 1em;

@ul-line-height: 2.34375em; 

@color-body: #666;
@color-heading: #222;

@heading-uppercase: false;
@heading-weight: 600;

@body-font-weight: 400;
@strong-font-weight: 600;

//////// Standard Space

@standard-space-n: 3 * @base-line-height;
@standard-space-s: 3 * @base-line-height;
@standard-space-mobile: 2 * @base-line-height;

//////// Boxed Layouts

@boxed-layout-width: 1280px;

//////// Buttons

@buttons-uppercase: false;

@button-radius: 0;
@button-padding-n: @base-line-height * .5;
@button-padding-s: @base-line-height * .6;
@button-padding-e: @base-line-height;
@button-padding-w: @base-line-height;
@border-width: 1px;
@button-text-size: 1em;
@button-font-weight: 400;
@button-spacing: @base-line-height/2;

//////// Icon

@icon-sm-size: 1em;
@icon-md-size: @h3-size;
@icon-lg-size: @h1-size;

//////// Nav Bar

@nav-uppercase-menu: false;
@nav-bg-color: #fff;
@navbar-height: 70px; // in pixels (not em)

	// for navs not constrained to container 

@nav-padding-e: @base-line-height * 2; 
@nav-padding-w: @base-line-height * 2;

@nav-logo-size: 70%; // default 70%;
@nav-item-padding: @base-line-height; // default 1em
@nav-item-font-size: 1em; // default 1em
@nav-item-font-weight: 400; // default 400
@nav-item-letter-spacing: 0px; // default 0
@nav-dropdown-width: 8 * @base-line-height; // default 12em
@nav-dropdown-bottom-padding: @base-line-height;

//////// Forms

@input-label-font-size: 1em;
@input-label-font-weight: 400;

@inputs-have-border: true;

@input-padding: 1em;
@input-border-radius: 0;
@input-border: 1px solid #ccc;
@input-background-color: #fff;
@input-placeholder-color: lighten(@color-body,20%);
@input-placeholder-size: 1em;

@checkbox-height: @base-line-height;
@checkbox-width: @base-line-height;
@checkbox-border-radius: 0;
@checkbox-padding: @base-line-height/2;

@radio-height: @base-line-height;
@radio-width: @base-line-height;
@radio-border-radius: 50%;
@radio-padding: @base-line-height/2;

//////// Accordions

@accordion-title-padding: @base-line-height/2;
@accordion-title-border: 1px solid;
@accordion-title-bg: none;
@accordion-title-bg-active: #eee;

@accordion-content-padding: @base-line-height;

//////// Breadcrumbs

@breadcrumbs-uppercase: false;
@breadcrumb-item-padding: @base-line-height; // space between breadcrumbs
@breadcrumb-font-size: 1em;

//////// Tabs

@tab-title-padding: 0 @base-line-height; // space between tabs

//////// Boxes

@box-padding: @base-line-height * 2;
@box-border: 1px solid #aaa;
@box-shadow-level: 1; // value from 1 - 5, shallow - deep

//////// Image Grid Settings

@default-gap-padding: @base-line-height/4;

//////// Sliders

@slider-paging-alignment: center;

@slider-paging-height: @base-line-height/2;
@slider-paging-width: @base-line-height/2;
@slider-paging-radius: @base-line-height/2;
@slider-paging-bg: none;
@slider-paging-bg-active: #fff;
@slider-paging-border: 2px solid #fff;
@slider-paging-spacing: @base-line-height/4; // space between each paging button

@slider-arrow-font: 'iconsmind';
@slider-arrow-right: '\e660';
@slider-arrow-left: '\e650';
@slider-arrow-size: 2em;

//////// Video Styling

@play-icon-default-color: #fff;
@play-icon-filled: true;
@play-icon-width-height: @base-line-height*3;
@play-icon-border: 2px solid @play-icon-default-color;
@play-icon-triangle: 6px;

//////// Modals

@default-modal-padding: 0;

//////// Notifications

@default-notification-padding: 0;
