// DESCRIPTION:
// Add the class "boxed-layout" to the <body> element.

@media all and (min-width: (@boxed-layout-width)){
	body.boxed-layout{
		padding: @base-line-height*2 0;
		background: darken(@color-bg-secondary, 5%);
		section:not([class*='bg-']):not(.imagebg),footer:not([class*='bg-']):not(.imagebg),nav:not([class*='bg-']):not(.bar--transparent):not(.bar--absolute),.tabs-container:not([class*='bg-']):not(.imagebg){
			background: @color-bg-site;
		}
		.nav-container, .main-container, > section, nav{
			max-width: @boxed-layout-width;
			margin: 0 auto;
		}
	}
}