/*! -- Stack Alerts -- */

.alert{
	border-radius: 6px;
	&:not(:last-child){
		margin-bottom: @base-line-height;
	}
	.alert__close{
		font-size: @h4-size;
	}
}

.ad {
    width: 100%; 
    display: none;
    color: white;
    text-align: center;
    padding: 17px 0;
    font-size: 16px;
    font-family: Amiri,serif;
    background: #090b5b url('../img/sand_water.png') 0px bottom no-repeat;

    img {
    	margin:0px;
    	padding: 0px;
    	width: 30px;
    	display: inline-block !important;
    }

    p {
    	padding: 0px;
    	margin: 0px;
    	display: inline-block !important;
    	@media all and (max-width: 767px){ 
		    padding: 0 50px;
		}
    }

    em {
    	display: inline-block;
    	padding-left: 10px;
    }
}



.ad strong {
    color: #4fd7e9
}

.ad .closeAd {
    position: absolute;
    right: 30px;
    top: 10px;
    color: white;
    display: block;
    font-size: 20px;
}

.formAd {
	border-radius: 10px; 
	padding: 50px 75px 115px;
	color: white;
	font-size: 20px;
    font-family: Amiri,serif;
    text-align: center;
    background: #090b5b url('../img/sand_water.png') -35px bottom no-repeat;

    em {
    	display: block;
    	padding-top: 50px;
    }

    strong {
    	text-transform: uppercase;
    	color: #4fd7e9;
    	letter-spacing: 3px;
    }
}

