/*! -- Stack Menu Toggle -- */

.menu-toggle{
	.box-shadow;
	width: @base-line-height*2;
	height: @base-line-height*2;
	border-radius: 50%;
	text-align: center;
	background: #fff;
	display: inline-block;
	.transition(0.2s,ease);
	i{
		font-size: @h3-size;
		position: relative;
		top: 13px;
		color: @color-dark;
	}
	&:hover{
		.box-shadow-wide;
		.translate3d(0,-2px,0);
	}
}