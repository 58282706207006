.carousel-meta {
	width: 100%;
	display: inline-block;
	text-align: right;
	margin: 0 -10px;
	p, i {
		display: inline-block;
		padding-right: 5px;
		margin-bottom: 0px;
	}
}

/*! -- Stack Sliders -- */

.flickity-prev-next-button{
	.transition(0.3s,ease);
	border-radius: 0;
	background: @color-dark;
	width: @base-line-height*1.5;
	height: @base-line-height*3;
	&:not(:hover){
		opacity: .5;
	}
	&:hover{
		background: @color-dark;
	}
	&.previous{
		left: 0;
	}
	&.next{
		right: 0;
	}
	&:before{
		color: #fff;
	}
}

.slider{
	&.slider--inline-arrows{
		.flickity-prev-next-button{
			background: none;
			&:before{
				font-size: @h3-size;
				color: @color-dark;
				content: '\e802';
			}
			&.previous{
				&:before{
					content: '\e801';
				}
			}
		}
	}
	&.slider--columns{
		.previous{
			left: 15px;
		}
		.next{
			right: 15px;
		}
	}
	&.border--round{
		.slides{
			li{
				border-radius: 6px;
				overflow: hidden;
			}
		}
	}
	&.boxed--border{
		.slides{
			li{
				border: 1px solid #ececec;
			}
		}
	}
	.slides{
		&.expand {
			a {
				position: relative;
				display: block;

			}
			a:hover {
				transition: all 0.3s ease;
				&:after {
					content: '\f424';
					height: 25px;
					width: 25px;
					position: absolute;
					bottom: 20px;
					right: 10px;
					color: white;
					font-family: "Font Awesome 5 Pro"; 
					font-weight: 400;
				}
			}
		}
		li.slide{
			.feature:not(.feature-9){
				margin-bottom: 0;
			}
		}
	}
}

.slide{
	.boxed{
		&:last-child{
			margin-bottom: 0;
		}
	}
	&:not([class*='col-']){
		>img{
			&:only-child{
				width: 100%;
			}
		}
	}
}

.bg--dark,.bg--primary,.imagebg:not(.image--light){
	.slider{
		&.slider--inline-arrows{
			.flickity-prev-next-button{
				&:before{
					color: #fff;
				}
			}
		}
	}
}

@media all and (max-width: 990px){
	.flickity-prev-next-button{
		display: none;
	}
}

@media all and (min-width: 768px){
	h2+.slider,.h2+.slider{
		margin-top: @base-line-height*2;
	}
}

.slider.slider--ken-burns:not(.parallax) .slide.is-selected{
	> .background-image-holder, > img:only-child{
		animation: kenBurns 15s ease alternate infinite;
		-webkit-animation: kenBurns 15s ease alternate infinite;
	}
}