.gf_middle_third {
	padding: 0 16px !important;
}

.page-template-tpl-booking, .page-template-tpl-booking-form {
	background-color: #f3f9ff !important;
	.main-container {
		width: 900px;
		margin: 0 auto;
		.booking-form {
			background-color: white;
			border: 1px solid #e4f1fd;
			padding: 2em;
			.logo {
				padding: 0 0 2em;
			}
			.get-started {
				border: 1px solid #e4f1fd;
				padding: 2em;
				background-color: #f3f9ff;
			}
			h1 {
				font-size: 34px;
			    color: #080b5c;
			    letter-spacing: 0;
			    line-height: 48px;
			    font-weight: 500;
			    margin-bottom: .5625em;
			    font-family: Roboto,sans-serif!important;
			    text-transform: uppercase;
			}
			h3 {
				color: #080b5c;
				font-weight: 400;
				font-size: 20px;
				line-height: 24px;
			}
			p {
				font-size: .95em;
			}
			ul {
				margin: 0px;
				padding: 0px;
				line-height: inherit;
				li {
					margin: 0px;
					padding: 0px;
					font-size: .95em;
					label {
						font-size: .95em;
						font-weight: 600;
						margin: 0px;
						padding: 0px;
					}
				}
			}
		}
	}
	
}
