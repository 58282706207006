/*! -- Stack Footers -- */

footer{
	background-image: linear-gradient(-180deg, #E8E8E3 0%, #F8F8F8 100%);
	position: relative;
	z-index: 2;

	#footer-img {
		padding: 0 0 @base-line-height 0;
		margin: @base-line-height 0 (@base-line-height / 2) 0;
		border-bottom: 1px solid #E4F1FD;
	}

	.footer-call-out {
		margin: 0 0 (@base-line-height * 4) 0;
		h2 {
			font-family: 'Amiri', serif;
			font-size: 24px;
			color: @color-primary;
			font-weight: 100;
			font-style: italic;
			line-height: 28px;
		}
		p {
			color: #575757;
			font-family: 'Amiri', serif;
			font-weight: 400;
			line-height: 18px;
		}
	}

	.type--fine-print{
		font-size: .9em;
		&:not(p){
			font-size: .9em;
		}
	}

	&:not(.bg--primary):not(.bg--dark){
		a, address{
			color: @color-primary-2;
			// font-size: .9em;
			font-weight: 400;
		}
		h6 {
			color: @color-primary-2;
			margin-bottom: @base-line-height/2;
		}
		.social-list {
			a{
				color: @color-primary-1;
			}
		}
		.type--fine-print{
			color: @color-primary-1;
		}
	}
	a.type--fine-print{
		&:not(:first-child){
			margin-left: @base-line-height;
		}
	}
	.logo{
		max-height: @base-line-height*.9;
	}
	ul{
		&:not(.list-inline):not(.slides){
			> li{
				line-height: @base-line-height*1.25 !important;
			}
		}
	}
	&.footer-2{
		.row{
			&:last-child{
				margin-top: @base-line-height*2;
			}
		}
	}
	&.footer-5{
		.row{
			[class*='col-']{
				>div{
					&:nth-last-child(2){
						margin: @base-line-height/2;
						.type--fine-print{
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&.footer-6{
		.footer__lower{
			background: @color-bg-secondary;
			margin-top: @base-line-height;
			padding: @base-line-height 0;
		}
		&.bg--dark{
			.footer__lower{
				background: darken(@color-dark, 5%);
			}
		}
		&.bg--secondary{
			.footer__lower{
				background: darken(@color-bg-secondary, 2%);
			}
		}
		&.bg--primary{
			.footer__lower{
				background: darken(@color-primary, 5%);
			}
		}
	}
	&.footer-7{
		padding: @base-line-height 0;
	}
}

@media all and (min-width: 768px){
	footer{
		.list-inline{
			+.btn{
				position: relative;
				bottom: 4px;
			}
		}
		.logo{
			margin: 0;
			&:not(:last-child){
				margin-right: @base-line-height;
			}
		}
		&.footer-3{
			.logo{
				+ul{
					position: relative;
					top: 5px;
					a{
						color: @color-dark;
					}
				}
			}
			.social-list{
				position: relative;
				top: 9px;
			}
			.row:last-child{
				margin-top: @base-line-height;
			}
		}
		&.footer-4{
			.logo{
				+span{
					position: relative;
				}
			}
			form{
				position: relative;
				bottom: 4px;
			}
			.row{
				&:last-child{
					margin-top: @base-line-height;
				}
			}
		}
		&.imagebg:not(.image--light){
			span,p,a{
				color: #fff;
			}
		}
	}
	.footer-1{
		.type--fine-print{
			position: relative;
			top: 2px;
		}
	}
}

@media all and (max-width: 767px){
	footer{
		text-align: center !important;
		.footer-box {
			margin-bottom: 1.5em;
		}
		.small-footer-box {
			text-align: center !important;
		}
		.social-list{
			// margin: @base-line-height 0;
		}
		#footer-img {
			margin: 0px;
		}
	}
	.footer-4{
		form{
			margin: @base-line-height 0;
		}
		.logo{
			margin: 0;
		}
	}
	.footer-6{
		.footer__lower{
			.social-list{
				margin-top: @base-line-height;
			}
		}
	}
	.footer-7{
		.social-list{
			margin-top: @base-line-height;
		}	
	}
	.footer-1{
		.social-list{
			margin-top: @base-line-height;
		}
		.logo{
			display: block;
			margin-top: @base-line-height;
			margin-bottom: @base-line-height/2;
			margin-left: auto;
			margin-right: auto;
		}
	}
}