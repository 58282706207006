/*! -- Stack Images -- */

img{
	&:last-child{
		margin-bottom: 0;
	}
	&.flag{
		max-height: @base-line-height;
	}
	&.image--sm{
		&:not(:last-child){
			margin-bottom: @base-line-height/2;
		}
	}
	&.promo{
		&.border--round{
			border: 1px solid #ececec;
		}
	}
}

p.lead{
	img{
		max-height: 1.68421053em;
	}
}

.imagebg{
	h1,h2,h3,h4,h5,h6{
		position: relative;
	}
	&:not(.image--light){
		span{
			color: #fff;
		}
	}
	&.border--round{
		overflow: hidden;
	}
}

section.parallax{
	.row{
		.background-image-holder{
			transform: none !important;
			top: 0 !important;
		}
	}
}

.triptych{
	&.border--round{
		img{
			position: relative;
			border-radius: 6px;
			width: 33.333333%;
			float: left;
			margin: 0;
			&:first-child,&:last-child{
			}
			&:nth-child(2){
				.scale(1.2);
				z-index: 2;
				.box-shadow-wide;
			}
		}
	}
}

.gallery{
	>div[class*='col-']{
		margin-bottom: 30px;
	}
}

.gallery-1{
	overflow: hidden;
	position: relative;
	.gallery__image{
		&:not(:last-child){
			margin-bottom: 30px;
		}
	}
	> div[class*='col-']{
		&:first-child{
			> .gallery__image{
				height: 800px;
			}
		}
		&:last-child{
			> .gallery__image{
				height: calc(800px/2 - 15);
			}
		}
	}
}

.gallery__image{
	position: relative;
	overflow: hidden;
}

@media all and (max-width: 767px){
	.gallery-1{
		.gallery__image{
			max-height: 300px;
			margin-bottom: 15px;
			&:not(:last-child){
				margin-bottom: 15px;
			}
		}
	}
}

.section--ken-burns{
	overflow: hidden;
	> .background-image-holder, > img:only-child{
		animation: kenBurns 15s ease alternate infinite;
		-webkit-animation: kenBurns 15s ease alternate infinite;
	}
}

#bookingForm {
	h2 {
		font-size: 30px;
	    color: @color-primary;
	    letter-spacing: 0;
	    line-height: @base-line-height;
	    font-weight: 300;
	}
}

.tilt-mask {
	position: relative;
}

.tilt-container {
	position: relative;
	transform-style: preserve-3d;
}

.tilt-overlay {
	box-shadow: 0 0 50px 0 rgba(51, 51, 51, 0.3);
    z-index: 2;
    transition: all 0.3s ease;
    overflow: hidden;
}

.tilt-bg {
	transition: all 0.3s ease;
	&:after {
		background: #000;
	    content: "";
	    display: block;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    opacity: .2;
	}
}

.tilt-link {
	display: block;
}

.slick-slider {
	h2 {
		font-size: 30px;
	    color: @color-primary;
	    letter-spacing: 0;
	    line-height: @base-line-height;
	    font-weight: 300;
	}
}

.slick-slide {
	display: block;
	cursor: pointer;
	outline: none !important;
	width: 565px;
}

.slick-list {
	overflow: visible !important;
}

.slide-component {
	width: 500px;
	position: relative;

	-webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
}

.slick-slide:not(.slick-center) .slide-component {
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    transform: scale(.8);
}

.slick-slide:not(.slick-center) .tile-overlay,  {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
}

.tilt-overlay {
	position: absolute;
	left: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}

.slick-slide {
	padding: 0 2rem;
	&.slick-center {
		.tilt-container {
			.tilt-overlay {
				left: 5%;
			    bottom: 7%;
			    width: 90%;
			    height: 85%;
			}
		}
	}
}

.slick-arrow {
	position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 10;
    &.slick-next {
    	right: -webkit-calc(50% - 275px);
	    right: calc(50% - 275px);
	    -webkit-transform: translate(200%,-50%);
	    -ms-transform: translate(200%,-50%);
	    transform: translate(200%,-50%);
	    font-size: 20px;
    }
    &.slick-prev {
    	left: -webkit-calc(50% - 280px);
	    left: calc(50% - 280px);
	    -webkit-transform: translate(-200%,-50%);
	    -ms-transform: translate(-200%,-50%);
	    transform: translate(-200%,-50%);
	    font-size: 20px;
    }
}


.slide-meta {
	padding: 10px 0;
	.slide-title {
		font-size: 18px;
		line-height: 30px;
	    color: @color-primary-1;
	    font-weight: 500;
	    text-transform: uppercase;
	    margin: 0px;
	    padding: 0px;
	    letter-spacing: 1px;
	}
	.slide-type {
		font-size: 12px;
		line-height: 14px;
	    color: @color-primary;
	    font-weight: 400;
	    display: block;
	    margin: 0px;
	    padding: 0px;
	    letter-spacing: 1px;
	}
}

.main-preview-player {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-js {
  flex: 3 1 70%;
}