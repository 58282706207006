// GOOGLE MAPS

.home-map {
	width: 100%;
	padding: 50px 0;
	position: relative;
    margin-top: -300px;
    margin-bottom: -200px;
    @media all and (max-width: 767px){ 
         margin-bottom: -400px;
    }
	svg {
		width: 100%;
		height: 800px;
		padding: 10px !important;
		pointer-events:all;
		fill: @color-primary !important;
		position: relative !important;
		transition: all .2s ease; 
		a {
			fill: @color-primary !important;
		}
		#anegoda-island {
			transition: all .2s ease; 
			display: block;
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				// transform: translateY(-5px);
				
				//-webkit-filter: drop-shadow(0px 0px 5px #06d9fc);
				// -webkit-filter: url(#shadow) drop-shadow(0px 0px 0px #06d9fc);
				// -moz-filter: url(#s) drop-shadow(0px 0px 0px #06d9fc);
				filter: url(#shadow);
			}
		}
		// #neccar-island {
		// 	transition: all .2s ease; 
		// 	&:hover {
		// 		transform: translateY(-5px);
		// 		filter: url(#shadow); 
		// 	}
		// }
		#virgin-gorda-island {
			transition: all .2s ease; 
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#cooper-island {
			transition: all .2s ease;
			stroke-width: 10;
			stroke-opacity: 0.0001; 
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		// #ginger-island {
		// 	transition: all .2s ease; 
		// 	stroke-width: 100;
		// 	stroke-opacity: 0.0001;
		// 	&:hover {
		// 		transform: translateY(-5px);
		// 		filter: url(#shadow); 
		// 	}
		// }
		#salt-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#peter-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#guana-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#norman-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#dog-island {
			transition: all .2s ease; 
			stroke-width: 5;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		// #scrub-island {
		// 	transition: all .2s ease; 
		// 	stroke-width: 100;
		// 	stroke-opacity: 0.0001;
		// 	&:hover {
		// 		transform: translateY(-5px);
		// 		filter: url(#shadow); 
		// 	}
		// }
		// #marina-island {
		// 	transition: all .2s ease; 
		// 	stroke-width: 100;
		// 	stroke-opacity: 0.0001;
		// 	&:hover {
		// 		transform: translateY(-5px);
		// 		filter: url(#shadow); 
		// 	}
		// }
		// #beef-island {
		// 	transition: all .2s ease; 
		// 	stroke-width: 100;
		// 	stroke-opacity: 0.0001;
		// 	&:hover {
		// 		transform: translateY(-5px);
		// 		filter: url(#shadow); 
		// 	}
		// }
		#tortola-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
		#jost-van-dyke-island {
			transition: all .2s ease; 
			stroke-width: 10;
			stroke-opacity: 0.0001;
			&:hover {
				//transform: translateY(-5px);
				filter: url(#shadow); 
			}
		}
	}
	
}

.contact-header {
	padding-top: 3em;
	padding-bottom: 2em;
}

.contact-block {
	h1 {
		font-size: 36px;
		margin-bottom: 15px;
	}
	a {
		color: @color-primary-1;
		font-weight: 400;
	}
}

.contact-form {
	input, textarea {
		background-color: #eee;
		color: #333;
		border: none;
		padding: 10px 15px !important;
	}
	label {
		display: none !important;
	}
	#field_1_3 {
		margin-top: 20px;
	}
	.gform_button {
		&.button {
			background: @color-primary;
			color: white;
			border: none;
			border-radius: 10px;
			text-transform: uppercase;
			font-weight: 500;
			padding: 10px 30px !important
		}
	}
}

#contact-map {
	height: 600px;
	width: 100%;
	position: relative;
	border-bottom: 1px solid #eee;

	#content {
		padding: 10px;
		h1 {
			font-size: 14px;
			line-height: 26px;
			font-weight: 500;
			color: @color-primary;
			margin: 0px !important;
			padding: 0px !important;
		} 
		p {
			margin: 0px !important;
			padding: 0px !important;
			line-height: 26px;
			font-weight: 400;
			color: @color-primary;
			&.red {
				color: @color-primary-1;
			}
		}
	}
}

#map {
	width:100%;
    height:1000px;
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .gm-ui-hover-effect {
	img {
		filter: invert(1) !important;
		margin-bottom: 0px;
	}
}

.gm-style-iw {
	width: 250px !important;
	top: 15px !important;
	left: 0px !important;
	background-color: white;
	box-shadow: 0 0px 15px rgba(0,0,0,0.20);
	border-radius: 5px;
	overflow: inherit !important;
	@media all and (max-width: 767px){ 
        width: 222px !important;
    }

}

.marker-arrow {
	position: absolute;
	left: 50%;
	bottom: -20px;
	background: 0 0;
	margin-left: -10px;
	i {
		font-size: 2em;
		text-shadow: 0 0px 15px rgba(0,0,0,0.20);
	}
}

#iw-container {
	margin-bottom: 0px;
	width: 100%;
}


#iw-container .iw-title {
	font-size: 1.2em;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: @color-primary;
	font-weight: 400;
	margin: 0;
	width: 100%;
	padding-bottom: 15px;

	i {
		color: @color-primary;
		padding-left: 5px;
		font-size: .7em;
	}
}
#iw-container .iw-content {
	font-size: .9em;
	margin: 0px;
	padding: 20px 20px 10px;
	font-weight: 400;

	p {
		margin-bottom: 15px;
		color: #6f6e6f;
	}
	ul {
		margin: 0px;
		padding: 0px;
		li {
			display: inline-block;
			position: relative;
			padding-right: 5px;
			span {
				display: block;
				color: @color-primary;
				position: relative;
				height: 20px;
				width: 20px;
				margin-right: 10px;
				&:before {
					content: '';
					position: relative;
					height: 17px;
					width: 17px;
					background-size: cover;
					display: block;
				}
				&.Anchor {
					&:before {
						background-image: url('../img/anchor-blue.svg');
					}
				}
				&.Dock {
					&:before {
						background-image: url('../img/dock.svg');
						height: 18px;
					}
				}
				&.Food {
					&:before {
						background-image: url('../img/food-blue.svg');
						height: 18px;
					}
				}
				&.Fuel {
					&:before {
						background-image: url('../img/fuel-blue.svg');
					}
				}
				&.Mooring {
					&:before {
						background-image: url('../img/mooring-blue.svg');
						height: 18px;
					}
				}
				&.Water {
					&:before {
						background-image: url('../img/water-blue.svg');
					}
				}
			}
		}
	}
}

#iw-container img {
	width: 100%;
	margin-bottom: 0px;
	border-radius: 5px 5px 0 0;
}
}

#mapcontainer {
	position: fixed;
	z-index: -1;
	top: 0px;
	left: 0;
}

.controls {
	position: absolute;
	top: 200px;
	left: 50px;
	z-index: 2;
	background: white;
	box-shadow: 0 2px 34px 0 rgba(0,0,0,0.15);
	border-radius: 5px;
	width: 300px;

	@media all and (max-width: 767px){ 
        top: 0px;
		left: 0px;
		width: 100%;
		border-radius: 0;
		position: relative;
    }

	.filter-title {
		display: block;
		width: 100%;
		font-size: .9em;
		font-weight: 500;
		color: #666;
		padding: 20px 25px 15px;
		text-transform: uppercase;
		border-bottom: 1px solid #eee;
		overflow: hidden;
		cursor: pointer;

		i {
			font-size: 1.5em;
			padding: 5px;
			float: right;
		}
	}

	a {
		background: @color-primary;
		color: #fff;
		padding: 10px 0px;
		width: 100%;
		max-width: 100%;
		display: block;
		text-align: center;
		font-weight: 400;
		text-transform: uppercase;
		font-size: .9em;
		letter-spacing: 1px;
		border-radius: 0 0 5px 5px;
		@media all and (max-width: 767px){ 
	        border-radius: 0px;
	    }
		i {
			padding-left: 15px;
		}
	}

	ul {

		li {

			.input-checkbox {
				display: inline-block;
			}

			span {
				display: inline-block;
				font-size: .9em;
				text-transform: uppercase;
				color: @color-primary;
				font-weight: 500;
				letter-spacing: 1px;

				img {
					margin-right: 10px;
				}
			}
		}
	}

	form {
		padding: 20px 30px 15px;
		@media all and (max-width: 767px){ 
	        display: none;
	    }
	}

	.input-checkbox input, .input-radio input, .input-select input {
	    opacity: 0 !important;
	    height: 0 !important;
	    width: 0 !important;
	    position: absolute !important;
	}

	.input-checkbox label {
	    height: 27px;
	    background-color: @color-primary-1;
	    border: none;
		cursor: pointer;
		margin-bottom: 0px;
	}

	.input-checkbox.input-checkbox--switch label:before {
		left: 1px;
		top: 1px;
		border: none;
		background: white;
		width: 25px;
		height: 25px;
		box-shadow: 0 6px 3px 0 rgba(0,0,0,0.17);
	}
	.input-checkbox.input-checkbox--switch input:checked+label:before {
		transform: translateX(1.7em);
		background-color: white;
		border: none;
	}

	.input-checkbox.input-checkbox--switch input:checked+label {
		background-color: @color-primary;
	}
}


	
#maptext {
	margin-bottom: 200px;
}

.js-section, .section {
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
	padding-top: 4.0625em !important;
}

.itinerary-location {
	h2 {
		padding: 0;
		margin: 0px;
		span {
			display: block;
			padding: .1em 0;
			&.title-up {
				font-size: .4em;
				font-weight: 400;
				text-transform: uppercase;
				color: #ccc;
				letter-spacing: 10px;
				b {
					font-weight: 400;
					color: @color-primary;
				}
			}
			&.title-down {
				font-size: 1em;
				font-weight: 300;
				letter-spacing: 1px;
				padding-bottom: .5em;
				color: @color-primary;
			}
		}
	}
	figure {
		margin: 2em 0 1.5em -4em;
		padding: 0px;
		text-align: right;
		position: relative;
		z-index: 4;
		img {
			margin: 0px!important;
			padding: 0px;
		}
		figcaption {
			font-size: .7em;
			margin: 0px;
			padding: 0px;
			color: #aaa;
		}
	}
	p {
		font-size: .9em;
		&.text-intro {

		}
	}
	ul {
		margin: 0px;
		padding: 0px 0 0 3.5em;
		position: relative;
		&:before {
			position: absolute;
			content: '';
			border-left: 1px solid #eee;
			left: 10px;
			top: 0px;
			height: 200%;
			z-index: 0;
		}
		li {
			margin: .5em 0 2em;
			position: relative;
			padding: 0px;
			line-height: 1.5em;
			a {
				color: @color-primary-1;
			}
			p {
				@media all and (max-width: 767px){ 
					font-size: 12px;
			    }
			}
			label {
				margin: 0px 0 .25em;
				padding: 0px;
				display: block;
				@media all and (max-width: 767px){ 
					font-size: 14px;
			    }

				b {
					color: @color-primary;
					letter-spacing: 1px;
					font-weight: 500;
				}
				.loc-start {
					color: @color-primary-1;
					letter-spacing: 1px;
					font-weight: 500;
				}
				.loc-end {
					color: @color-primary-1;
					letter-spacing: 1px;
					font-weight: 500;
				}
			}
			&:before {
				content: '';
				position: absolute;
				left: -3.5em;
				top: 0px;
				height: 20px;
				width: 20px;
				background-size: cover;
				background-color: white;
				z-index: 3;
			}
			&.start {
				h4 {
					font-size: .7em;
					letter-spacing: 4px;
					text-transform: uppercase;
					color: #aaa;
					margin: 0px 0 .3em;
					padding: 0px;
					@media all and (max-width: 767px){ 
						font-size: 11px;
				    }
				}
				&:before {
					background-image: url('../img/dot-blue.svg');
				}
			}
			&.anchor {
				&:before {
					background-image: url('../img/anchor-blue.svg');
				}
			}
			&.moor {
				&:before {
					background-image: url('../img/mooring-ball.svg');
					height: 23px;
					width: 22px;
				}
			}
			&.dingy {
				&:before {
					background-image: url('../img/dinghy.svg');
					height: 22px;
				}
			}
			&.dine {
				&:before {
					background-image: url('../img/food-blue.svg');
					height: 23px;
					width: 23px;
				}
			}
			&.hike {
				&:before {
					background-image: url('../img/hike-blue.svg');
					height: 26px;
					width: 26px;
				}
			}
			&.shopping {
				&:before {
					background-image: url('../img/shopping-bag.svg');
					height: 25px;
				}
			}
			&.gps {
				&:before {
					background-image: url('../img/dot-blue.svg');
				}
			}
			&.snorkle {
				&:before {
					background-image: url('../img/snorkle-blue.svg');
					height: 22px;
					width: 22px;
				}
			}
			&.dive {
				&:before {
					background-image: url('../img/scuba-blue.svg');
				}
			}
			&.cocktails {
				&:before {
					background-image: url('../img/cocktail-blue.svg');
					height: 24px;
				}
			}
			&.beach {
				&:before {
					background-image: url('../img/beach-blue.svg');
					height: 24px;
					width: 24px;
				}
			}
			&.fishing {
				&:before {
					background-image: url('../img/fish-blue.svg');
					height: 20px;
    				width: 29px;
    				left: -3.7em;
				}
			}
			&.spa {
				&:before {
					background-image: url('../img/spa-blue.svg');
				}
			}
			&.swim {
				&:before {
					background-image: url('../img/pool-blue.svg');
					height: 22px;
					width: 22px;
				}
			}
			&.car {
				&:before {
					background-image: url('../img/car-blue.svg');
					height: 22px;
					width: 30px;
				}
			}
			&.tour {
				&:before {
					background-image: url('../img/tour-blue.svg');
					height: 22px;
					width: 22px;
				}
			}
			&.dock {
				&:before {
					background-image: url('../img/dock.svg');
					height: 24px;
				}
			}
			&.entertainment {
				&:before {
					background-image: url('../img/entertainment-blue.svg');
				}
			}
			&.pirate {
				&:before {
					background-image: url('../img/pirate-blue.svg');
					height: 24px;
				}
			}
			&.camera {
				&:before {
					background-image: url('../img/camera-blue.svg');
				}
			}
			&.groceries {
				&:before {
					background-image: url('../img/groceries-blue.svg');
				}
			}
			&.fuel {
				&:before {
					background-image: url('../img/fuel-blue.svg');
				}
			}
			&.jetski {
				&:before {
					background-image: url('../img/jetski-blue.svg');
					height: 21px;
    				width: 39px;
					transform: scale(.75);
					left: -3.8em;
				}
			}
		}
	}
}

/*! -- Stack Maps -- */

iframe[src*='google.com/maps']{
	border: none;
}

.map-container{
	min-height: 400px;
	&:not(:last-child){
		margin-bottom: @base-line-height;
	}
	&.border--round{
		overflow: hidden;
		z-index: 2;
		.gm-style{
			border-radius: 6px;
		}
	}
	iframe{
		left: 0;
	}
}

@media all and (min-width: 768px){
	.background-image-holder, .imageblock__content{
		.map-container{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
	.map-container{
		+div[class*='col-']{
			margin-top: @base-line-height*2;
		}
	}
}