[data-gradient-bg]{
	position: relative;
	background: @color-dark;
	> canvas{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		+.background-image-holder{
			opacity: .2 !important;
		}
	}
}