// DESCRIPTION:
// Defines the structure of bars for navigation / utility
//
//
// MARKUP:
//
// <div class="bar">
//   ...
// </div>
//
// MODIFIERS:
//.bar--sm        - Add class to bar to make slightly smaller with larger logo
//.bar--lg        - Add class to bar to make taller with larger logo
//.bar--xlg       - Add class to bar to make much taller with larger logo

.bar{
	padding: @base-line-height 0;
	.logo{
		margin: 0;
		position: relative;
		top: 4px;
	}
	.menu-horizontal{
		position: relative;
		top: 6px;
	}
	&:not([class*='visible-']){
		+.bar{
			margin-top: @base-line-height/2;
		}
	}
	&.bar--xs{
		padding: @base-line-height/4 0;
	}
	&.bar--sm{
		padding: @base-line-height/2 0;
	}
	&.bar--lg{
		padding: @base-line-height*1.5 0;
		.logo{
			top: 0;
		}
	}
	&.bar--xlg{
		padding: @base-line-height*2.5 0;
		.logo{
			top: 0;
		}
	}
	&.bg--dark{
		.logo-dark{
			display: none;
		}
	}
	&:not(.bg--dark):not(.bar--transparent){
		.logo-light{
			display: none;
		}
	}
}

@media all and (max-width: 767px){
	.bar{
		&.bar--mobile-sticky[data-scroll-class*='fixed'].pos-fixed{
			position: fixed;
			width: 100%;
			background: #fff;
			z-index: 999;
		}
		&.bar--mobile-sticky[data-scroll-class*='fixed'] + .bar.pos-fixed{
			top: @base-line-height * 2;
			position: fixed;
			width: 100%;
			background: #fff;
			z-index: 999;
		}
	}
}

// Desktop
@media all and (min-width: 991px){
	.bar__module{
		&:not(:only-child){
			display: inline-block;
		}
		&:not(:last-child){	
			margin-right: @base-line-height/2;
		}
	}
	.bar--transparent:not(.bar--dark){
		background: none;
		.logo-dark{
			display: none;
		}
		.logo-light{
			display: inline-block;
		}
		&:not(.pos-fixed){
			.menu-horizontal{
				> li{
					> a, > span{
						color: #fff;
					}
				}
			}
			.btn:not([class*='primary']){
				.btn__text{
					color: #fff;
				}
				border-color: rgba(255,255,255,.3);
				&:hover{
					border-color: rgba(255,255,255,.7);	
				}
			}
		}
	}
	.bar--absolute{
		position: absolute;
	}
	.bar--absolute, .pos-fixed{
		z-index: 99;
		width: 100%;
	}
	.bar{
		&.pos-fixed{
			position: fixed;
			top: 0;
			animation: fadeInDown .3s ease-out forwards;
			.box-shadow;
			&:not([class*='bg-']){
				background: @color-bg-site;
				.logo-dark{
					display: inline-block;
				}
				.logo-light{
					display: none;
				}
			}
			&.bg--dark{
				background: @color-dark;
			}
		}
	}
}

// Mobile
@media all and (max-width: 767px){
	.bar__module{
		margin-bottom: @base-line-height/2;
		+.bar__module{
			margin-top: @base-line-height;
		}
		.btn{
			display: block;
			+.btn{
				margin-left: 0 !important;
			}
			&:not(:last-child){
				margin-bottom: @base-line-height/2;
			}
		}
	}
	.bar{
		+nav.bar{
			padding-top: 0;
		}
	}
}