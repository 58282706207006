.radial{
	position: relative;
	.radial__label{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		margin-bottom: 0;
	}
}