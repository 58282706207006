// MARKUP:
// <section class="switchable switchable--switch">
//   <div class="switchable__text">
//      ...
//   </div>
// </section>
//
// MODIFIERS
// .switchable--switch - reverses the direction of content in the section

.switchable{
	position: relative;
	div[class*='col-']:first-child{
		float: left;
		&:not([class*='pull']):not([class*='push']){
			left: 0;
		}
		right: auto;
	}
	div[class*='col-']:last-child{
		float: right;
		&:not([class*='pull']):not([class*='push']){
			right: 0;
		}
		left: auto;
	}
	&.switchable--switch{
		div[class*='col-']:first-child{
			float: right;
			right: 0;
			left: auto;
			&:not([class*='pull']):not([class*='push']){
				left: auto;
			}
		}
		div[class*='col-']:last-child{
			float: left;
			left: 0;
			right: auto;
		}
	}
	.switchable__text{
		margin-top: @base-line-height * 2;
	}
	>div[class*='col-']{
		padding: 0;
	}
}