ul{
	&:last-child{
		margin: 0;
	}
}

.list-inline li{
	padding: 0 1em;
	margin-left: 0;
	&:first-child{
		padding-left: 0;
	}
	
	&:last-child{
		padding-right: 0;
	}
}

.list-inline.list-inline--narrow li{
	padding: 0 .5em;
}

.list-inline.list-inline--wide li{
	padding: 0 2em;
}