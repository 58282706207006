/*! -- Stack Features Large -- */
.call-to-action {
	.col-sm-3 {
		padding-right: 5px;
		padding-bottom: 5px;
	}
	.col-sm-3:nth-child(1) {

		img {

		}
	}
	.col-sm-3:nth-child(2) {
		img {

		}
	}
	.col-sm-3:nth-child(3) {
		img {

		}
	}
	.col-sm-3:nth-child(4) {
		img {

		}
	}
	.col-sm-3:nth-child(5) {
		img {
		}
	}
	.col-sm-3:nth-child(6) {
		img {
		}
	}
	.col-sm-3:nth-child(7) {
		img {
		}
	}
	@media all and (max-width: 767px){ 
		padding: 3.375em 0 !important;
	}
}

.slides {
	.hide-mobile {
		display: block;
		width: 100%;
	}
	.hide-desktop {
		display: none;
	}
	@media all and (max-width: 767px){ 
		.hide-mobile {
			display: none;
		}
		.hide-desktop {
			display: block;
		}
	}
}

.feature-large {
	.image {
		display: table-cell;
		vertical-align: middle;
		height: 250px;
	}

	&.partners {
		background: white;
	}
}

.text-bareboat {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Bareboat';
	    position: absolute;
	    z-index: 10;
	    right: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-bareboat {
		&:before {
			font-size: 120px;
			right: 0px;
		}
	}
}

.text-sail {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Sail Catamaran';
	    position: absolute;
	    z-index: 10;
	    right: -560px;
	    white-space: nowrap;
	}
}

@media all and (max-width: 767px){ 
	.text-sail {
		&:before {
			font-size: 120px;
			right: 0px;
		}
	}
}

.text-planning {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Planning';
	    position: absolute;
	    z-index: 10;
	    right: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-planning {
		&:before {
			font-size: 120px;
			right: 0px;
		}
	}
}

.text-sustenance {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Sustenance';
	    position: absolute;
	    z-index: 10;
	    right: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-sustenance {
		&:before {
			font-size: 120px;
			right: 0px;
		}
	}
}

.text-crewed {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Crewed';
	    position: absolute;
	    z-index: 10;
	    right: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-crewed {
		&:before {
			font-size: 120px;
			right: 0px;
		}
	}
}

.text-luxury {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Luxury';
	    position: absolute;
	    z-index: 10;
	    left: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-luxury {
		&:before {
			font-size: 120px;
			left: 0px;
		}
	}
}

.text-adventure {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Adventure';
	    position: absolute;
	    z-index: 10;
	    left: -20px;
	}
}

@media all and (max-width: 767px){ 
	.text-adventure {
		&:before {
			font-size: 90px;
			left: 0px;
		}
	}
}

.text-tradewinds {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Tradewinds';
	    position: absolute;
	    z-index: 10;
	    left: -20px;
	}
}

@media all and (max-width: 767px){ 
	.text-tradewinds {
		&:before {
			font-size: 90px;
			left: 0px;
		}
	}
}

.text-hassle-free {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Hassle Free';
	    position: absolute;
	    z-index: 10;
	    left: -20px;
	}
}

@media all and (max-width: 767px){ 
	.text-adventure {
		&:before {
			font-size: 90px;
			left: 0px;
		}
	}
}

.text-custom {
	position: relative;
	z-index: 10;
	&:before {
		opacity: .1;
	   	font-family: 'Amiri', serif;
	   	font-style: italic;
	    font-size: 175px;
	    color: #6d8dab;
	    letter-spacing: 0;
	    content: 'Custom';
	    position: absolute;
	    z-index: 10;
	    left: -100px;
	}
}

@media all and (max-width: 767px){ 
	.text-custom {
		&:before {
			font-size: 120px;
			left: 0px;
		}
	}
}

// .home-map {
// 	img {
// 		position: relative;
// 		margin-top: -250px;
// 	}
// }

@media all and (max-width: 767px){ 
	.home-map {
		img {
			margin-top: 0px;
		}
	}
}

.call-to-action {
	padding-bottom: 0px;
}

.action {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 50%;
	color: white;
	transform: translateY(-50%);
	z-index: 11;
	padding-top: 50px;
	padding-bottom: 50px;
	background: rgba(9,11,91, .9);
	width: 60%;

	h2 {
		font-family: 'Amiri', serif;
		font-size: 30px;
		color: #FFFFFF;
		font-style: italic;
		font-weight: 300;
		margin: 0px;
		padding: 0px;
	}

	h3 {
		font-family: Roboto, sans-serif;
		font-size: 48px;
		color: #FFFFFF;
		letter-spacing: -1px;
		text-align: center;
		font-weight: 300;
		margin: 0px;
		padding: 0px;
	}

	p {
		font-family: Roboto, sans-serif;
		font-size: 16px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		line-height: 25px;
		margin-top: 25px;
		margin-bottom: 40px;
	}
}

@media all and (max-width: 767px){  
	.action {
		width: 90% !important;
		h3 {
			font-size: 36px;
			line-height: 36px;
		}
	}
}

.action-white {
	h2 {
		font-family: 'Amiri', serif;
		font-size: 30px;
		color: #FFFFFF;
		font-style: italic;
		font-weight: 300;
		margin: 0px;
		padding: 0px;
	}

	h3 {
		font-family: Roboto, sans-serif;
		font-size: 48px;
		color: @color-primary;
		letter-spacing: -1px;
		text-align: center;
		font-weight: 300;
		margin: 0px;
		padding: 0px;
	}

	p {
		font-family: Roboto, sans-serif;
		font-size: 16px;
		letter-spacing: 0;
		text-align: center;
		line-height: 25px;
		margin-top: 25px;
		margin-bottom: 40px;
	}
}

.left-side-hero {

	.hero-text {

		label {
			font-family: Roboto, sans-serif;
			font-size: 16px;
			color: #FFFFFF;
			letter-spacing: 5px;
			line-height: 24px;
			padding-bottom: 15px;
			font-weight: 600;
			text-shadow: 0 0px 100px #000;
		}

		h3 {
			font-family: Roboto, sans-serif;
			font-size: 52px;
			color: #FFFFFF;
			letter-spacing: -1px;
			line-height: 58px;
			margin-bottom: 0px;
			font-weight: 300;
			text-shadow: 0 0px 100px #000;
		}

		p {
			font-family: Roboto, sans-serif;
			font-size: 20px;
			color: #FFFFFF;
			letter-spacing: 0;
			line-height: 30px;
			text-transform: initial;
			text-shadow: 0 0px 100px #000;
		}

	}
}

.left-side-hero {

	.hero-text {

		p {
			padding-left: 0px;
		}

	}
}

.right-side-hero {

	.hero-text {

		label {
			font-family: Roboto, sans-serif;
			font-size: 16px;
			color: #FFFFFF;
			letter-spacing: 5px;
			line-height: 24px;
			padding-bottom: 15px;
			font-weight: 600;
			text-shadow: 0 0px 100px #000;
		}

		h3 {
			font-family: Roboto, sans-serif;
			font-size: 52px;
			color: #FFFFFF;
			letter-spacing: -1px;
			line-height: 58px;
			margin-bottom: 0px;
			font-weight: 300;
			text-shadow: 0 0px 100px #000;
		}

		p {
			font-family: Roboto, sans-serif;
			font-size: 20px;
			color: #FFFFFF;
			letter-spacing: 0;
			line-height: 30px;
			text-transform: initial;
			text-shadow: 0 0px 100px #000;
		}

	}
}

.right-side-hero {

	.hero-text {

		p {
			padding-right: 0px;
		}

	}
}

.hero-text {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%) !important;
	z-index: 5;
	text-shadow: 0 0px 100px #fff;

	h2 {
		font-size: 36px;
		color: @color-primary;
		margin: 0px;
		padding: 0px;
		font-style: italic;
		font-weight: 400;
		font-family: 'Amiri', serif;
		text-shadow: 0 0px 100px #fff;
	}

	h1 {
		font-size: 52px;
		color: @color-primary;
		line-height: 75px;
		margin: 0px;
		margin-bottom: 0px !important;
		font-weight: 400;
		padding: 0px;
		font-family: 'Amiri', serif;
		text-shadow: 0 0px 100px #fff;
		span {
			color: @color-primary-1;
		}
	}

	hr {
		margin: 0px auto;
		padding: 0px;
		width: 100%;
	}

	p {
		font-size: 18px;
		color: #FFFFFF;
		font-weight: 500;
		letter-spacing: 1.8px;
		line-height: 30px;
		margin-top: 15px;
		margin-bottom: 15px;
		text-transform: uppercase;
		text-shadow: 0 0px 20px #000;
	}

}

.hero {
	position: relative;

	.hide-mobile {
		display: none;
	}

	.hide-desktop {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	#relative {
		position: relative;
	}

	a {
		&.white {
			padding-left: @base-line-height*1.5 !important;
			padding-right: @base-line-height*1.5 !important;
			font-size: 14px !important;
		}
	}

	.dot {
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		bottom: 100px;
		width: 30px;
		z-index: 2;
		&:after {
			    position: absolute;
			    content: '';
			    height: 200px;
			    margin-left: auto;
			    margin-right: auto;
			    left: -5px;
			    right: 0;
			    width: 1px;
			    background: white;
			    top: 23px;
		}
		&.left {
			right: inherit;
			left: 10px;
			bottom: -600px;
		}
	}
}

@media all and (max-width: 767px){ 
	.hero {
		.hide-mobile {
			display: block;
		} 
		.hide-desktop {
			display: none;
		} 
		.dot {
			bottom: 50px;
		}
	}
	.hero-text {
		top: 57%;
		h2 {
			font-size: 28px;
		}
		h1 {
			line-height: 48px;
		}
		p {
			line-height: 22px !important;
			span {
				display: block;
				font-size: .9em;
				text-transform: capitalize;
			}
			i {
				display: none;
			}
		}		
	}
	.right-side-hero, .left-side-hero {
		h3 {
			font-size: 42px !important;
			line-height: 42px !important;
		}
		label {
			margin-bottom: 0px !important;
			padding-bottom: 5px !important;
			font-size: 14px !important;
		}
		p {
			font-size: 1.3em !important;
			line-height: 1.3em !important;
		}

		.btn.mt-5 {
			margin-top: 0px !important;
		}
		.btn.white {
			padding-left: @base-line-height*1.5 !important;
			padding-right: @base-line-height*1.5 !important;
			font-size: 14px !important;
		}
	}
}

.tripadvisor {
	background: #E8E8E3 url('../img/tripadvisor.svg') no-repeat;
	width: 100%;
	background-size: cover;
	background-position: top;
	min-height: 980px;
	position: relative;
	margin-bottom: 0px;
	padding-bottom: 0px;
	padding-top: 0px;

	.reviews-contain {
		
		position: absolute;
		top: 0px;
		right: 0px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		@media (min-width: 992px) { 
			flex-direction: row;
		}

		.ta {
			width: 100%;
			@media (min-width: 992px) { 
				margin-left: 25px;
				width: auto;
				border-radius: 0 0 10px 10px;
			}

			.ta-body {
				background-color: #34E0A1;
				@media (min-width: 992px) { 
					border-radius: 0 0 0px 10px;
				}
			}
	
			.ta-content {
				font-size: 12px !important;
			}
	
			.cdsLocName {
				max-width: 250px !important;
				padding: 0 10px;
			}
	
			.cdsROWContainer {
				padding: 0 10px;
			}
	
			.white {
				width: inherit !important;
				padding: inherit !important;
				border-radius: 0 0 10px 10px !important;
			}
	
			.logo {
				top: 1px !important;
			}
		}
	
		.ga {
			width: 100%;
			@media (min-width: 992px) { 
				width: auto;
				border-radius: 0 0 10px 10px;
			}

			.ta-body {
				background-color: #FFAA00;
				@media (min-width: 992px) { 
					border-radius: 0 0 0px 10px;
				}
			}
	
			.ta-content {
				font-size: 12px !important;
			}
	
			.cdsLocName {
				max-width: 250px !important;
				padding: 0 10px;
			}
	
			.cdsROWContainer {
				padding: 0 10px;
			}
	
			.white {
				width: inherit !important;
				padding: inherit !important;
				border-radius: 0 0 10px 10px !important;
			}
	
			.logo {
				top: 1px !important;
			}
		}
	}
	

	.reviews {

		padding: @base-line-height*3 100px @base-line-height*5;
		position: relative;

		.next {
			color: white;
			position: absolute;
			right: 20px;
			&:hover {
				i {
					color: white;
				}
			}
			i {
				font-size: 2em;
			}
		}

		.prev {
			color: white;
			position: absolute;
			left: 20px;
			&:hover {
				i {
					color: white;
				}
			}
			i {
				font-size: 2em;
			}
		}

		.review-body {
			// background: #FFFFFF;
			// box-shadow: 0 2px 24px 0 rgba(0,0,0,0.50);
			width: 100%;
			border-radius: 5px;
			padding: 50px 0;

			.testimonial {
				width: 100%;
				overflow: hidden;

				.slick-dotted, .slick-dots {
					display: none !important;
				}

				.item {
					background: #FFFFFF;
					box-shadow: 0 2px 24px 0 rgba(0,0,0,0.50);
					border-radius: 10px;
					padding: 25px;
					// height: 400px;
					margin: 0 15px;
				}

				// .slide-component {
				// 	width: 33.33%;
				// }

				.testimonial-headline {
					margin-bottom: 10px;
					h4 {
						font-size: 2em;
						display: block;
						font-style: italic;
						margin: 0px;
						padding: 0px;
						font-weight: 500;
						font-family: 'Amiri', serif;
						color: @color-primary-1;
						line-height: 1.1em;
						text-align: left;
					}
					em {
						font-size: 1.5em;
						position: relative;
					}
				}

				.testimonial-review {
					font-weight: 600;
					margin-bottom: 25px;
					text-align: left;
				}
				.tesimonial-person {
					text-align: left;
				}
				.author {
					display: block;
					text-align: left;
				}

			}

			.ta-logo {
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0px;
				right: 0px;
				bottom: 61px;
				img {
					width: 250px;
				}
			}
		}

		h1 {
			font-family: Roboto, sans-serif;
			font-size: 52px;
			color: #FFFFFF;
			letter-spacing: -1px;
			font-weight: 300;
			line-height: 58px;
		}
	}
}

@media all and (max-width: 767px){ 
	.tripadvisor { 
		min-height: 760px;
		.reviews {
			text-align: center;
			padding: @base-line-height*3 0px @base-line-height*5;
			.prev {
				left: -5px
			}
			.next {
				right: -5px;
			}

			h1 {
				font-size: 42px;
				line-height: 48px;
				img {
					display: block;
					margin: 0 auto 15px;
				}
			}
		}
		.ta {
		    width: 145%;
		    transform: scale(.7);
		    left: -85px;
		}

	}
}


@media all and (min-width: 768px){
	.staggered{
		div[class*='col-']{
			&:nth-child(2):last-child{
				margin-top: @base-line-height*7;
				&:not(:last-child){
					margin-bottom: @base-line-height*5;
				}
			}
			.feature{
				&:not(:last-child){
					margin-bottom: @base-line-height*5;
				}
			}
		}
	}
}

.feature-large {
	position: relative;
	padding-top: @standard-space-n * 2;
	padding-bottom: @standard-space-s * 2;
	img.ajl {
		margin-left: -2px;
	}
	img.ajr {
		margin-left: -3px;
	}
	.choose-boat {
		border: 2px solid #eee;
		transition: all .5s ease;
		&:hover {
			border: 2px solid @color-primary;
		}
	}
	&:after {
		    position: absolute;
		    content: '';
		    height: 100%;
		    margin-left: auto;
		    margin-right: auto;
		    left: -5px;
		    right: 0;
		    width: 1px;
		    background: #eee;
		    top: -5px;
		    z-index: -1;
	}
	&.last {
		&:after {
			display: none;
		}
	}
	h4{
		&:first-child{
			margin-bottom: 0.68421052631579em;
		}
	}
	h2 {
		font-size: 44px;
		color: @color-primary;
		letter-spacing: 0;
		line-height: 48px;
		font-weight: 100;
		margin-bottom: @base-line-height/3;
		font-family: 'Amiri', serif;
		text-transform: uppercase;
	}
	p {
		font-size: 16px;
		letter-spacing: 0;
		line-height: 28px;
	}
	.feature{
		&:not(.boxed){
			margin-bottom: 30px;
		}
	}
	.feature-3{
		&.text-center{
			p{
				margin: 0 auto;
			}
		}
	}
	.lead{
		+.feature-large__group{
			margin-top: @base-line-height*2;
		}
	}
	.feature-large__group{
		overflow: hidden;
	}
}

@media all and (max-width: 767px){ 
	.feature-large{ 
		padding: 3.375em 1.375em;
		background: white;
		margin-bottom: 1.375em;
		h2 {
			font-size: 38px;
			line-height: 40px;
		}
	}
	.btn.white {
		margin-top: 10px !important;
	}
}

.feature-large-1{
	.lead{
		margin-bottom: 2.73684210526316em;
	}
}

@media all and (min-width: 768px){
	.feature-large-2{
		div[class*='col-']{
			&:first-child{
				margin-top: @base-line-height*3;
			}
			&:last-child{
				margin-top: @base-line-height*4;
			}
		}
	}
}

@media all and (max-width: 767px){
	.feature-large-2{
		img{
			margin: @base-line-height 0;
		}
	}
}

.feature-large-7{
	&.switchable{
		.boxed{
			div[class*='col-']:only-child{
				float: none;
			}
		}
	}
}

.feature-large-13{
	p.lead +.text-block{
		margin-top: @base-line-height * 2;
	}
	div[class*='col-']{
		.text-block + .text-block{
			margin-top: @base-line-height * 1.5;
		}
	}
}

@media all and (min-width: 1200px){
	.feature-large-13:not(.text-center){
		.text-block{
			p{
				max-width: @base-line-height * 14;
			}
		}
	}
}

.feature-large-14{
	h5{
		margin-bottom: @base-line-height/4;
	}
	.boxed{
		margin-bottom: 0;
		&.boxed--border{
			img{
				border-radius: 6px;
			}
		}
	}
}

@media all and (min-width: 768px){
	.feature-large-14{
		&.switchable{
			.switchable__text{
				margin-top: @base-line-height*3;
			}
		}
	}
}

.menu {
	// padding: 9rem 0;
	position: relative;
	// margin: 100px 0 0; 
}

.menu .backgroundBg {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	height: 750px;
	width: 100%;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	opacity: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 2; 
}

.menu .backgroundBg.active {
	opacity: 1;
	z-index: 3; 
}

.menu .backgroundBg#bg-one {
	background-image: url("../../src/img/menu/1.jpg"); 
}

.promotion {
	.hero-text {
		bottom: 10%;
		top: inherit !important;
	}
	.logo {
		width: 300px;
		@media all and (max-width: 767px){ 
			width: 200px;
		}
	}
	img {
		margin: 0px !important;
		padding: 0px !important;
	}
	h3 {
		font-size: 42px !important;
		font-weight: 300 !important;
		font-family: 'Amiri', serif !important;

		@media all and (max-width: 767px){ 
			font-size: 36px !important;
			line-height: 36px !important;
			margin: 15px 0 !important;
		}
	}
}

.quick-quote {
	background-image: radial-gradient(circle at 10% 0%, #3EC3CB 0%, #13AAB3 100%);
	border-radius: 8px;
	h5 {
		color: #0A0347;
		text-transform: uppercase;
		font-size: 18px;
	}
	input {
		border-radius: 10px !important;
		background-color: transparent !important;
		border: 1px solid #fff !important;
		padding: 0 15px !important;
		color: #fff !important;
		font-weight: 600;
		&::placeholder {
			font-weight: 600 !important;
			color: #fff !important;
		}
	}
	.gform_button {
		border-radius: 1000px !important;
		background-color: #0A0347 !important;
		color: #fff !important;
		text-transform: uppercase !important;
		text-align: center !important;
		padding: 10px 0px !important;
		height: inherit !important;
		font-size: 14px !important;
		border: none !important;
	}
	#gform_confirmation_message_11 {
		color: white !important;
		padding-bottom: 10px !important;
	}
}

#did-you-know {
	background: #FAFAFA;
	border: 2px solid #EEEEEE;
	border-radius: 12px;
	h2 {
		font-size: 20px;
		color: #080756;
		letter-spacing: 0;
		line-height: 30px;
		font-weight: bold;
	}
	p {
		font-family: Roboto-Light;
		font-size: 18px;
		color: #6F6F6F;
		letter-spacing: 0;
		line-height: 24px;
	}
	a {
		color: #D83237;
		font-weight: bold;
	}
}

.secondary-banner {
	background-color: #0A085F;
	padding: 75px 0 150px;
	h1 {
		font-family: Amiri;
		font-size: 48px;
		color: #FFFFFF;
		letter-spacing: -2px;
		font-weight: normal;
		margin-bottom: 10px !important;
		padding: 0px;
	}
	h2 {
		font-family: Roboto-Bold;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: -1.25px;
		margin-bottom: 30px;
		padding: 0px;
	}
	p {
		font-size: 18px;
		color: #FFFFFF;
		letter-spacing: 0;
		line-height: 24px;
		font-style: normal;

		&.text-sm {
			font-size: 16px;
		}
	}
}

.affix-margin {
	margin-top: -175px;
}

.picture-container {
	height: 100%;
	min-height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.deals-text {
	display: flex;
	justify-content: center;
	// align-items: center;
	flex-direction: column;
	text-align: left;
	h3 {
		font-family: Amiri-Regular;
		font-size: 36px !important;
		color: #0A085F;
		letter-spacing: -1.5px;
		line-height: 42px;
		margin-bottom: 20px;
		padding: 0px;
		font-weight: normal;
	}
	p {
		font-family: Roboto-Regular;
		font-size: 14px;
		color: #777777;
		letter-spacing: 0;
		line-height: 24px;
	}
	.deals-wrap {
		padding: 0 120px;
		@media all and (max-width: 965px){ 
			padding: 0px;
		}
	}
	
}