// MARKUP
//
// <div class="twitter-feed twitter-feed--slider">
//   <div class="tweets-feed" data-widget-id="492085717044981760"></div>
// </div>
//
// MODIFIERS:
// .twitter-feed--slider - make each tweet a slide
// [data-widget-id] - a uninque widget ID obtained from twitter

.twitter-feed{
	.user{
		display: none;
	}
	.interact{
		display: none;
	}
	.timePosted{
		font-size: .87em;
	}
}