/*! -- Stack Nav Fullscreen -- */

.menu-fullscreen{
	.pos-absolute{
		width: 100%;
	}
	color: #fff;
	a{
		color: #fff;
		&:hover{
			text-decoration: none;
		}
	}
	.social-list{
		margin-right: 0;
	}
	&:before{
		background: rgba(0,0,0,0.9);
	}
	.modal-content .modal-close-cross{
		right: @base-line-height*2;
		top: 1em;
	}
}

// Mobile
@media all and (max-width: 767px){
	.menu-fullscreen{
		.pos-bottom{
			position: relative;
		}
	}
}