/*! -- Stack Features Small -- */

.features {
	h3 {
		font-family: Roboto, sans-serif;
		font-size: 52px;
		color: @color-primary;
		letter-spacing: -1px;
		text-align: center;
		line-height: 58px;
		font-weight: 100;
	}
}

.feature{
	&:not(.boxed){
		margin-bottom: 60px;
	}
	&.feature--featured{
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 4px;
			background: @color-primary;
			left: 0;
		}
	}
	h5{
		margin: 0;
	}
	h4{
		margin-bottom: 0.342105263157895em;
		+ p{
			// max-width: @base-line-height*12;
		}
	}
	i+h5{
		margin-top: @base-line-height;
	}
	i+h4{
		margin-top: 0.68421052631579em;
	}
	i.icon--lg+h4{
		margin-top: 1.36842105263158em;
	}
	i.icon--lg+.h5{
		margin-top: @base-line-height/2;
	}
	img+.boxed{
		border-radius: 0 0 6px 6px;
		border-top: none;
	}
}

a.feature{
	display: block;
	text-decoration: none;
	font-weight: normal;
	color: @color-body;
	img:first-child:not(:last-child){
		margin-bottom: @base-line-height/2;
	}
}

@media all and (max-width: 767px){
	a.feature{
		margin-bottom: @base-line-height/2 !important;
	}
}

.imagebg:not(.image--light){
	.feature{
		&.bg--white{
			a:not(.btn){
				color: @color-primary;
			}
			.label{
				color: #fff;
			}
		}
	}
}

@media all and (max-width: 767px){
	.feature{
		.feature__body{
			form{
				.row{
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
}

section.text-center .feature-6{
	h4 + p{
		margin: 0 auto;
	}
}

@media all and (min-width: 768px){
	.row{
		div[class*='col-']{
			&:nth-child(1):nth-last-child(3),&:nth-child(2):nth-last-child(2),&:nth-child(3):last-child{
				.feature{
					margin-bottom: 0;
				}
			}
		}
	}
}

a.block{
	>.feature{
		.transition(0.3s,ease);
	}
	&:hover{
		>.feature{
			.box-shadow;
		}
	}
}

.feature-1{
	min-height: @base-line-height*6;
	transition: all 0.5s ease;
	&:hover {
		transform: translateY(-10px);
		box-shadow: 0 10px 34px 0 rgba(0,0,0,.2);
	}
	p{
		margin: 0;
	}
	p.lead{
		min-height: 5.05263157894737em;
	}
	> img{
		&:first-child{
			border-radius: 6px 6px 0 0;
		}
	}
	>a.block{
		img{
			border-radius: 6px 6px 0 0;
			width: 100%;
			height: inherit;
		}
		+.feature__body{
			border-radius: 0 0 6px 6px;
		}
	}
	&[class*='box-shadow']{
		border-radius: 6px;
		.transition(0.35s,ease-out);
		&:hover{
			.translate3d(0,-10px,0);
			.box-shadow-wide;
		}
	}
}

.hover-shadow{
	border-radius: 6px;
	backface-visibility: hidden;
	overflow: hidden;
	border: 1px solid #ececec;
		.transition(0.35s,ease-out);
		&:hover{
			.translate3d(0,-10px,0);
			.box-shadow-wide;
		}
}

.bg--dark{
	.hover-shadow{
		border-color: #222;
		&:hover{
			box-shadow: 0 23px 40px rgba(0, 0, 0, 1);	
		}
	}
}

.feature-2{
	overflow: hidden;
	.feature__body{
		width: 75%;
		float: right;
	}
	h5{
		margin-bottom: @base-line-height/4;
	}
	p{
		&:last-child{
			margin: 0;
		}
	}
	i{
		width: 25%;
		float: left;
	}
}

.feature-large{
	.feature-2{
		+ .feature-2{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}

@media all and (max-width: 990px){
	.feature-2{
		.feature__body,i{
			width: 100%;
			float: none;
		}
		p{
			&:first-of-type{
				margin-top:@base-line-height/2;
			}
		}
	}
}

.feature-3{
	i{
		margin-bottom: 0.16666666666667em;
	}
	p{
		min-height: @base-line-height*4;
	}
}

.feature-4{
	.btn{
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		border-radius: 0;
		padding: @base-line-height/2;
		&:hover{
			transform: none;
		}
	}
	p{
		min-height: @base-line-height*5;
		&:last-of-type{
			margin-bottom: @base-line-height*2;
		}
	}
}

.feature-5{
	i{
		width: 25%;
		float: left;
	}
	.feature__body{
		width: 75%;
		float: right;
	}
	p{
		min-height: @base-line-height*3;
		&:last-of-type{
			margin-bottom: @base-line-height/2;
		}
	}
	&:not([class*='bg-']){
		color: @color-dark;
	}
}

.feature-6{
	p{
		min-height: @base-line-height*5;
		&:last-child{
			margin: 0;
		}
	}
}

.feature-7{
	height: @base-line-height*7;
	&[data-overlay]{
		&:before{
			border-radius: 6px;
			.transition(0.3s,ease);
		}
		&:hover{
			&:before{
				opacity: .75;
			}
		}
	}
	.background-image-holder{
		border-radius: 6px;
	}
	h3{
		margin: 0;
	}
	margin-bottom: 30px;
}

.row--gapless{
	.feature-7{
		margin: 0;
		border-radius: 0;
		.background-image-holder{
			border-radius: 0;
		}
		&:before{
			border-radius: 0;
		}
	}
}

@media all and (max-width: 767px){
	.feature-7{
		.pos-vertical-center{
			top: 50%;
			.translate3d(0,-50%,0);
		}
	}
}

.feature-8{
	&:not(.boxed){
		margin-bottom: 0;
	}
	padding: @base-line-height*1.5 0 @base-line-height*2 0;
	.feature__body{
		max-width: 70%;
		margin: 0 auto;
	}
	p{
		min-height: @base-line-height*3;
	}
}

@media all and (max-width: 990px){
	.feature-8{
		.feature__body{
			max-width: 85%;
		}
	}
}

@media all and (max-width: 767px){
	.feature{
		p{
			min-height: auto;
			margin-left: auto;
			margin-right: auto;
		}
		&.boxed{
			margin-bottom: 15px;
		}
		&:not(.boxed){
			margin-bottom: 30px;
		}
		&.feature-8{
			margin-bottom: 0;
		}
	}
}

.feature-8{
	img{
		max-height: @base-line-height*8;
	}
}

.feature-9{
	h4{
		margin: 0;
	}
	&:not(.boxed){
		margin-bottom: 30px;
	}
}

@media all and (min-width: 768px){
	.features-small-14{
		.text-block{
			margin-bottom: @base-line-height*2;
		}
		h4{
			min-height: 2.736842105263158em;
		}
	}
}

.feature {
	h4 {
		font-size: 20px;
		color: @color-primary;
		letter-spacing: 0;
		text-align: center;
		line-height: 30px;
	}
	p {
		margin-right: 0px;
	}
}