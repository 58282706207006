/*! -- Stack Twitter Feeds -- */

.tweets-feed{
	.interact{
		display: none;
	}
	.user{
		a{
			&:hover{
				text-decoration: none;
			}
		}
		img{
			border-radius: 50%;
		}
	}
}

.tweets-feed-1{
	border-radius: 6px;
	border: 1px solid #ececec;
	overflow: hidden;
	li{
		overflow: hidden;
		padding: @base-line-height;
		padding-bottom: 0;
		&:not(:last-child){
			border-bottom: 1px solid #ececec;
		}
	}
	.user{
		width: 20%;
		float: left;
		img{
			max-height: @base-line-height*2;
			margin-right: @base-line-height/4;
		}
		[data-scribe="element:name"],[data-scribe="element:screen_name"]{
			display: none;
		}
		margin-bottom: @base-line-height/2;
	}
	.tweet,.timePosted{
		width: 80%;
		float: right;
	}
	.tweet{
		margin-bottom: @base-line-height/4;
	}
	.timePosted{
		font-size: @h6-size;
		line-height: @h6-line-height;
	}
	&:not(:last-child){
		+.btn{
			margin-top: @base-line-height*1.5;
		}
	}
}

.imagebg,.bg--dark{
	.tweets-feed-1{
		border-color: rgba(255,255,255,.1);
		li{
			&:not(:last-child){
				border-color: rgba(255,255,255,.1);
			}
		}
	}
}

.tweets-feed-2{
	.user{
		display: none;
	}
	.timePosted{
		display: none;
	}
	li{
		&:before{
			font-family: 'socicon';
			content: "\e08d";
		}
		overflow: hidden;
		.tweet{
			width: 85%;
			float: right;
		}
	}
}

.tweets-feed.slider{
	.user{
		img{
			display: inline-block;
			clear: both;
			margin-bottom: @base-line-height/2;
		}
		[data-scribe="element:name"]{
			display: none;
		}
		[data-scribe="element:screen_name"]{
			display: block;
			font-size: @h4-size;
			line-height: @h4-line-height;
			font-weight: normal;
		}
	}
	.tweet{
		margin-top: 1.36842105263158em;
		margin-bottom: 0.68421052631579em;
		font-size: @h4-size;
		line-height: @h4-line-height;
	}
	.timePosted{
		margin: 0;
	}
	&:not(:last-child){
		+.btn{
			margin-top: @base-line-height*1.5;
		}
	}
}