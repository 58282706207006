.radial{
	margin-left: auto;
	margin-right: auto;
	.radial__label{
		transition: opacity .3s ease;
		-webkit-transition: opacity .3s ease;
	}
	&:not(.radial--active){
		.radial__label{
			opacity: 0;
		}
	}
	&:not(:last-child){
		margin-bottom: @base-line-height;
	}
}

@media all and (max-width: 767px){
	.radial{
		margin-bottom: @base-line-height;
	}
}