/*! -- Stack Loader -- */

.loader{
	border: 3px solid @color-primary;
    border-radius: 30px;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    position: fixed;
    z-index: 10;
    top: 50%;
    width: 30px;
    animation: pulsate .7s ease-out infinite;
    &.loader--fade{
    	opacity: 0;
    	animation: none;
    }
}

@keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}