/*! -- Stack Notifications -- */

.notification{
	margin: @base-line-height;
	box-shadow: none;
	&:not([class*='bg--']){
		background: none;
	}
	>.boxed{
		margin: 0;
	}
	>.feature, .feature__body{
		margin-bottom: 0;
	}
}

.search-box{
	width: 100%;
	margin: 0;
	&.notification--reveal{
		z-index: 9999;
	}
	padding: @base-line-height;
	.notification-close-cross{
		top: @base-line-height*1.25;
		right: @base-line-height;
	}
	.box-shadow;
}

.notification-close-cross{
	&.notification-close-cross--circle{
		background: #222;
	    width: 2em;
	    height: 2em;
	    text-align: center;
	    border-radius: 50%;
	    color: #fff;	
	}
}